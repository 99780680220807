<template>
  <v-container align-center justify-center text-center class="my-2">
    <v-row class="d-flex justify-center align-center text-center">
      <v-btn height="60px" color="primary" ripple class="mx-2" @click="loginWithFacebook">
        <v-icon dark>mdi-facebook</v-icon>
      </v-btn>
      <v-btn height="60px" color="primary" ripple class="mx-2" @click="loginWithGoogle">
        <v-icon dark>mdi-google</v-icon>
      </v-btn>
      <v-btn height="60px" color="primary" ripple class="mx-2" @click="loginWithApple">
        <v-icon dark>mdi-apple</v-icon>
      </v-btn>
      <!-- Error Alert -->
      <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
    </v-row>
  </v-container>
</template>

<script>
import useAuth from '../composables/useAuth';

export default {
  setup() {
    const { loginWithFacebook, loginWithGoogle, loginWithApple, error } = useAuth();
    return {
      loginWithFacebook,
      loginWithGoogle,
      loginWithApple,
      error,  
    };
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: center;
}
</style>