export default {
  none: (value) => value,
  required: (value) => !!value || "Field Required",
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail.";
  },
  phone: (value) => (value && value.length == 11) || "Must be 11 digits",
  accountNumber: (value) => (value && value.length == 8) || "Must be 8 digits",
  nationalInsurance: (value) =>
    (value && value.length == 9) || "Must be 9 characters",
  name: (value) => {
    const pattern = /^(\w.+\s).+/;
    return pattern.test(value) || "Provide forename & surname";
  },
  sortCode: (value) => {
    const pattern = /(\d{2}-?){2}\d{2}/;
    return pattern.test(value) || "Invalid Sort Code";
  },
  password: (value) => !!value || "Password Required",
  confirmPassword(password) {
    return (value) =>
      value == password ||
      "Your password and confirmation password do not match";
  },
  locationName: (value) => value != "Demo" || "Demo name is reserved",
};
