import { ref, onMounted } from 'vue';
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
} from 'firebase/auth';
import { initializeApp, getApps } from 'firebase/app';
import { firebaseConfig } from '@/config/env';
import { generateBranchLoginLink } from '@/helpers/utilities';
import  useAccountStore  from '@/composables/useAccountStore';

export default function useAuth() {
  const error = ref(null);
  const authenticatedUser = ref(null);
  let app = null;

  // Initialize Firebase
  if (!getApps().length) {
  app = initializeApp(firebaseConfig);
  }
  
  const auth = getAuth();
  const { createUser, selectedLocale, deal, offering, code, originalLink, checkEmail } = useAccountStore();

  // Auth State Changed Listener
  onMounted(() => {
    onAuthStateChanged(auth, (user) => {
      authenticatedUser.value = user;
    });
  });

  // SignIn with Provider (Facebook, Google, Apple)
  const signInWithProvider = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      await handleSignIn(result.user);
      error.value = null;
    } catch (err) {
      console.error('Authentication error:', err);
      error.value = err.message;
    }
  };

  // Magic Link Authentication
  const actionCodeSettings = {
    url: `${process.env.VUE_APP_MAGIC_LINK_URL}`,
    handleCodeInApp: true,
  };

  const sendMagicLink = async (emailAddress) => {
    console.log(process.env.VUE_APP_MAGIC_LINK_URL);
    try {
      await sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', emailAddress);
    } catch (err) {
      console.error('Error sending magic link:', err);
      error.value = err.message;
    }
  };

  const signInWithMagicLink = async () => {
    const url = window.location.href;
    let emailAddress = window.localStorage.getItem('emailForSignIn');
    if (!isSignInWithEmailLink(auth, url) || !emailAddress) return;

    try {
      const result = await signInWithEmailLink(auth, emailAddress, url);
      await handleSignIn(result.user);
      window.localStorage.removeItem('emailForSignIn');
    } catch (err) {
      console.error('Error signing in with magic link:', err);
      error.value = err.message;
    }
  };

  // Handle SignIn (Common Logic)
  const handleSignIn = async (user) => {
    if (!user) return;
    await checkEmail(user.email);
    const loginDeepLink = await generateBranchLoginLink(user.displayName || 'Default Name', user.email, originalLink);
    const payload = {
      name: user.displayName || 'Default Name',  
      email: user.email,
      deal_id: deal?.id,
      offering: offering,
      code: code,
      referringLink: originalLink,
      locale: selectedLocale.key,
      loginDeepLink,
    };
    await createUser(payload);
  };

  // Social Login Methods
  const loginWithFacebook = () => signInWithProvider(new FacebookAuthProvider());
  const loginWithGoogle = () => signInWithProvider(new GoogleAuthProvider());
  const loginWithApple = () => signInWithProvider(new OAuthProvider('apple.com'));

  return {
    loginWithFacebook,
    loginWithGoogle,
    loginWithApple,
    sendMagicLink,
    signInWithMagicLink,
    error,
    authenticatedUser,
    app,
    auth,
  };
}
