const state = {
  isAnnual: false,
  currency: "USD",
};
const getters = {
  annual: (state) => state.isAnnual,
  currency: (state) => state.currency,
};
const mutations = {
  SET_ANNUAL(state, isAnnual) {
    state.isAnnual = isAnnual;
  },
  SET_CURRENCY(state, curr) {
    state.currency = curr;
  },
};
const actions = {
  setAnnual({ commit }, payload) {
    commit("SET_ANNUAL", payload);
  },
  setCurrency({ commit }, payload) {
    commit("SET_CURRENCY", payload);
  },
};

export const subscribe = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
