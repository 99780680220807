import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCarousel,{attrs:{"height":"240px","hide-delimiters":true},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"white"},[_c('div',{staticClass:"grey-fill"},[_c(VIcon,_vm._g({attrs:{"large":"","color":"black"}},on),[_vm._v(" mdi-chevron-left ")])],1)])]}},{key:"next",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"white"},[_c('div',{staticClass:"grey-fill"},[_c(VIcon,_vm._g({attrs:{"large":"","color":"black"}},on),[_vm._v(" mdi-chevron-right ")])],1)])]}}])},_vm._l((_vm.carouselItems),function(item){return _c(VCarouselItem,{key:item.id},[_c(VRow,{staticClass:"fill-height mx-16",attrs:{"align":"center","justify":"center"}},[_c(VCol,[_c(VImg,{staticClass:"mx-auto",attrs:{"src":item.img,"position":"center","max-width":"40","eager":true}}),_c('div',{staticClass:"mt-2 caption font-weight-bold text-center"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"mt-2 text-center font-weight-bold secondaryGrey--text"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }