<template>
  <div class="container d-flex flex-column align-content-center mt-4">
    <div class="text--black text-center text-body font-weight-bold">
      {{ unlockText }}:
    </div>
    <v-img
      class="mx-auto my-2"
      :src="imgSrc"
      max-width="80%"
      max-height="150px"
      contain
      position="center"
      :eager="true"
    ></v-img>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  props: {
    imgSrc: String,
  },
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    unlockText() {
      return getStringForKey(this.selectedLocale, "unlocked");
    },
  },
};
</script>

<style scoped>
.container {
  width: 85%;
  border-radius: 5px;
}
</style>