import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import { alert } from "./modules/alert";
import { account } from "./modules/account";
import { subscribe } from "@/views/subscribe/store";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
    subscribe,
  },
  plugins: [createPersistedState()],
});


export const useStore = () => {
  return store;
}