<template>
  <div class="d-flex justify-center">
    <div class="d-flex justify-center" v-for="i in parseInt(steps)" :key="i">
      <div class="progress-circle" :style="i <= currentStep ? style : ''"></div>
      <div
        v-if="i < steps"
        class="progress-bar"
        :style="i < currentStep ? style : ''"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Number,
    currentStep: Number,
    themeColor: String,
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    style() {
      if (this.themeColor != null) {
        return `background-color: #${this.themeColor}`;
      }
      return "background-color: #0d002d";
    },
  },
  methods: {},
};
</script>

<style scoped>
.progress-circle {
  height: 18px;
  width: 18px;
  background-color: #bcbcbc;
  border-radius: 50%;
  display: inline-block;
}
.progress-bar {
  width: 40px;
  height: 2px;
  margin-top: 8px;
  background-color: #bcbcbc;
}
.complete-fill {
  background-color: #0d002d;
}
</style>