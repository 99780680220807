<template>
  <div>
    <deal-top-bar v-if="showDealBar" class="deal-bar"></deal-top-bar>
    <locale-selector class="flags" />
    <div
      v-if="!hideLogo"
      :class="showCenterLogo ? 'logo-centered mb-10' : 'logo-left mb-10'"
    >
      <div v-if="showCenterLogo">
        <v-img
          alt="WithU Logo"
          contain
          src="@/assets/logo-center.png"
          height="65"
          position="center"
          :eager="true"
        />
      </div>
      <div v-else>
        <v-img
          alt="WithU Logo"
          class="shrink mr-2"
          contain
          :src="whichLogo"
          height="30"
          position="center left"
          :eager="true"
        />
      </div>
      <v-btn
        v-if="showLogout"
        color="secondary"
        dark
        class="mt-2 black--text font-weight-bold text-subtitle-1"
        style="height: 40px"
        @click="logout"
      >
        <span> Logout </span>
      </v-btn>
    </div>

    <!-- <div class="primary pa-3">
    <div :style="!mobile && 'padding-left:100px'">
      <v-img
        alt="WithU Logo"
        class="shrink mr-2"
        contain
        src="@/assets/withu-logo-full.png"
        transition="scale-transition"
        height="30"
        position="center left"
      />
    </div>
  </div> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import back from "@/components/back";
import dealTopBar from "@/components/deal-top-bar";
import localeSelector from "@/components/locale-selector";
import { getDealStringForKey } from "@/translations/utilities";

export default {
  components: {
    back,
    dealTopBar,
    localeSelector,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      deal: "account/deal",
      dealStrings: "account/getDealStrings",
      selectedLocale: "account/getSelectedLocale",
    }),
    user() {
      return this.account.register.user;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    showBarBackground() {
      return this.$route.name == "Subscribe";
    },
    showLogout() {
      return this.$route.name == "Manage" && this.user;
    },
    showCenterLogo() {
      if ((this.hasDeal || this.routeName == "subscribe") && !this.isDesktop) {
        return true;
      }
      if (this.routeName == "download" && !this.isDesktop) {
        return true;
      }
      return false;
    },
    routeName() {
      return this.$route.name ? this.$route.name.toLowerCase() : "";
    },
    showDealBar() {
      return this.hasDeal && this.isDesktop && this.routeName == "home";
    },
    whichLogo() {
      if (this.isDesktop) {
        if (this.routeName == "subscribe") {
          return require("@/assets/withu-logo-black.png");
        } else if (this.routeName == "upgrade") {
          return require("@/assets/withu-logo-black.png");
        } else if (this.routeName == "download") {
          return require("@/assets/withu-logo-black.png");
        } else {
          if (this.hasDeal) {
            return require("@/assets/withu-logo-black.png");
          } else {
            return require("@/assets/withu-logo-white.png");
          }
        }
      } else {
        return require("@/assets/withu-logo-full-dark.png");
      }
    },
    isRedeem() {
      return this.$route.path == "/redeem";
    },
    hasPartnershipBranding() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.external_partner.enabled"
      );
    },
    hideLogo() {
      return false;
      // return this.isRedeem && this.hasPartnershipBranding;
    },
  },
  methods: {
    ...mapActions({
      logout: "account/logout",
    }),
  },
  mounted() {
    // bus.$on("updateLayout", (data) => {
    //   this.useCenterLogo = true;
    // });
  },
};
</script>
<style scoped>
.deal-bar {
  position: absolute;
  top: 0;
}
.logo-centered {
  position: absolute;
  width: 100%;
  margin: 20px auto 0 auto;
  top: 0;
}
.logo-left {
  position: absolute;
  margin: 20px 0 0 20px;
  top: 0;
}
.flags {
  position: absolute;
  z-index: 100;
  top: 0px;
  right: 0px;
  margin: 30px 20px 0 0;
}
</style>
