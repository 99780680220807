<template>
  <div class="mt-5 py-5">
    <p class="align-left pb-1 text-left text--primary font-weight-bold text-h7">
      Send Email Link
    </p>
    <div>
      <v-form ref="form" v-model="valid">
        <v-text-field
          outlined
          class="black--text"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          prepend-inner-icon="fa-magic"
        ></v-text-field>
        <v-btn
          block
          width="100%"
          height="40px"
          :disabled="!canSendEmail"
          color="primary"
          @click="sendMagicLinkEmail"
        >
          <font-awesome-icon mr-2 icon="magic" />
          <span class="ml-2"> Send Magic Link</span>
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { isSignInWithEmailLink } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import useAuth from "@/composables/useAuth";
export default {
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const auth = getAuth();
    const email = ref("");
    const emailSent = ref(false); // To prevent multiple sends
    const valid = ref(false);
    const emailRules = [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];
    const { sendMagicLink, signInWithMagicLink } = useAuth();

    // Check for magic link in URL when component is mounted
    onMounted(() => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        signInWithMagicLink();
      }
    });

    const canSendEmail = computed(() => valid.value && !emailSent.value);

    const sendMagicLinkEmail = async () => {
      await sendMagicLink(email.value);
      emailSent.value = true; // Prevents multiple sends
    };

    return {
      email,
      valid,
      emailRules,
      sendMagicLinkEmail,
      canSendEmail,
    };
  },
};
</script>
