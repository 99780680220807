<template>
  <div class="bar-container">
    <div class="text-container mx-auto">
      <div class="primary1--text text-h6 font-weight-bold">{{ largeText }}</div>
      <div class="primary1--text text-body-2 ml-4">
        {{ smallText }}{{ partner != null ? ` ${through} ${partner}` : "" }}.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  computed: {
    ...mapGetters({
      deal: "account/deal",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),
    through() {
      return getStringForKey(this.selectedLocale, "through");
    },
    largeText() {
      let dealText = getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.deal.header_bar_large"
      );
      return (
        dealText || getStringForKey(this.selectedLocale, "congratulations")
      );
    },
    smallText() {
      let dealText = getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.deal.header_bar_small"
      );
      return dealText || getStringForKey(this.selectedLocale, "offer");
    },
    partner() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.deal.partner"
      );
    },
  },
};
</script>

<style scoped>
.bar-container {
  width: 100vw;
  height: 70px;
  background-color: rgba(122, 104, 130, 0.3);
}
.text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 600px;
}
</style>

