<template>
  <div class="row">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="button-body row align-center justify-center"
          v-bind="attrs"
          v-on="on"
        >
          <div class="row align-center justify-center">
            <flag :code="selectedLocale.flag" />
            <div class="ml-2 text-caption font-weight-bold">
              {{ selectedLocale.key.toUpperCase() }}
            </div>
          </div>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(locale, index) in selectableLocales"
          :key="index"
          @click="changedLocale(locale)"
        >
          <div class="row align-center justify-center">
            <flag :code="locale.flag" />
            <div class="ml-2 text-caption font-weight-bold">
              {{ locale.key.toUpperCase() }}
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { supportedLocales } from "@/translations/utilities";

export default {
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    selectableLocales() {
      return supportedLocales.filter((l) => l.key != this.selectedLocale.key);
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      switchLocale: "account/switchLocale",
    }),
    changedLocale(locale) {
      this.switchLocale(locale);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.button-body {
  width: 80px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
</style>
