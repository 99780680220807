import { service } from "@/service";
import { router } from "@/router";
import { v4 as uuidv4 } from "uuid";
import {
  getStringsForLocale,
  getStartLocale,
  supportedLocales,
  getStringForKey,
  getDealStringForKey,
} from "@/translations/utilities";

import { getAuth, signInWithEmailLink } from "firebase/auth";
import base64 from "base-64";
import isEmail from "validator/es/lib/isEmail";
import { getProducts } from "./product-list";
import * as subs from "./subscriptionConstants";

const _ = require("lodash");

const state = {
  loading: false,
  initLoading: false,
  error: null, // redeem: false,
  checkedDomain: null,
  siteStrings: {},
  dealStrings: {},
  dealOffering: null,
  dealId: null,
  currency: {
    didUpdate: false,
    currency: "USD",
    country: null,
  },
  analytics: {
    anonymous_id: uuidv4(),
    user_id: null,
    email: null,
    location: {},
  },
  redeem: {
    adblock: null,
    data: null,
    code: null,
    deal: null,
    verifiedAt: null,
    referringLink: false,
    vces: {
      frameUrl: null,
      correlationId: null,
      granted: false,
      error: "Help Error Here",
    },
    fromApp: false,
    appFlowToken: null,
    appRedirectURL: null,
    appFlowUserId: null,
    showReturnToAppMessage: false,
  },
  register: {
    isPreRegToken: false,
    tokenVerificationError: null,
    isSavedPreRegistration: false,
    savedPreRegistrationError: null,
    existingUser: false,
    name: null,
    email: null,
    agreed_compliance_marketing: true,
    agreed_data_sharing: false,
    deal_id: null,
    offering: null,
    uid: null,
    user_id: null,
    currency: null,
    user: null,
  },
  download: {
    link: null,
  },
  stripe: null,
  upgrade: {
    currentProduct: null,
    canUpgrade: false,
    existingAccountToSubscriber: false,
    existingDealWasBest: false,
    paidToFreeBlock: false,
  },
  selectedLocale: supportedLocales[0],
  cancellationInstructions: {
    requiresCancellation: false,
    store: null,
  },
};

const actions = {
  defaultToLocale({ commit }) {
    let locale = getStartLocale();
    let strings = getStringsForLocale(locale);
    commit("setLocale", locale);
    commit("setStrings", strings);
  }, //EVENTS
  page({ commit, state }, { name, properties }) {
    const url = "/analytics";

    const payload = {
      ...state.analytics,
      type: "page",
      name,
      properties,
    };

    return service.post(url, payload);
  },
  events({ commit, state }, { event, properties }) {
    const url = "/analytics";

    const payload = {
      ...state.analytics,
      type: "event",
      event,
      properties,
    };

    return service.post(url, payload);
  },
  setTimeout({ commit, state, dispatch }, branchMatchId) {
    commit("setTimeout", branchMatchId);
    if (state.redeem.adblock) {
      dispatch("events", {
        event: "Adblock Detected",
        properties: {},
      });
    }
  },
  async setBranchData({ commit, dispatch, state }, data) {
    //TO DO: check expiry
    commit("setBranchData", data);
    commit("setShowReturnToAppMessage", false);

    var id;
    var offering;
    var referringLink;
    var enforceRedeemCode;
    console.log("Set Branch Data", data);
    console.log("Set Branch Data", data.data);
    console.log("Set Branch Data", data.data_parsed);

    if (data && data.data_parsed && data.data_parsed["~id"]) {
      id = data.data_parsed["~id"];
      var rl = data.data_parsed["~referring_link"];

      state.redeem.appRedirectURL = rl;

      referringLink = rl.split("?")[0];
      offering = data.data_parsed["offering"];
      enforceRedeemCode = data.data_parsed["enforce_code_entry"];
      dispatch("events", {
        event: "Web Link Opened",
        properties: data.data_parsed,
      });
    }
    // id = 'test';
    // referringLink = 'https://app.withutraining.com/philtest08113';
    // referringLink = "https://download.withu.fit/vhUtBWcaKzb"; // vces deal id t3FqBzf5Gp01GF3WTiyY
    // referringLink = 'https://download.withu.fit/e/tjjg2Ori7kb';
    // referringLink = "https://download.withu.fit/e/tjjg2Ori7kb";
    // referringLink = "https://download.withu.fit/e/Nr2gmDflhrb"
    // referringLink = "https://download.withu.fit/e/oKH7LBULZsb";
    // referringLink = "https://download.withu.fit/e/tQPQUbhcIwb";

    // offering = 'withu_promotional1';
    // enforceRedeemCode = true;

    if (!id && !referringLink) {
      commit("setInitLoading", false);
      return false;
    } else if (enforceRedeemCode) {
      const url = `/getDealWebConfig?referringLink=${encodeURIComponent(
        referringLink
      )}&locale=${state.selectedLocale.key}`;

      console.log("getDealWebConfig", url);
      console.log("getDealWebConfig", referringLink);
      console.log("getDealWebConfig", state.selectedLocale.key);

      commit("clearDeal");
    
      return service
        .get(url)
        .then((result) => {
          console.log("getDealWebConfig result: ", result);

          if (result?.content || result?.data_sharing) {
            commit("setDealId", result.deal_id);
            commit("setDealStrings", {
              content: result.content,
              dataSharing: result.data_sharing,
            });
            router.push("/redeem").catch((err) => {});
          }
        })
        .catch((err) => {
          commit("setInitLoading", false);
          return;
        });
    } else {
      const url = `/getDeal`;

      const payload = {
        ...state.analytics,
        linkId: null, //id,
        offering,
        referringLink,
      };

      // console.log(payload)

      commit("clearDeal");

      return service
        .post(url, payload)
        .then((result) => {
          console.log("getDeal result: ", result);

          commit("setCode", null);
          commit("setDeal", result);

          if (result.web.content || result.data_sharing) {
            commit("setDealStrings", {
              content: result.web.content,
              dataSharing: result.data_sharing,
            });
          }
          commit("setIsReferringLink", true);
          console.log("current route", router.currentRoute);
          console.log("current route query", router.currentRoute.query);
          router
            .push({ path: "/redeem", query: router.currentRoute.query })
            .catch((err) => {});
        })
        .catch((err) => {
          // console.log(err);
          commit("setInitLoading", false);
          return;
        });
    }
  },

  async getDeal({ commit, state }, payload) {
    const url = `/getDeal`;

    const p = {
      ...state.analytics,
      ...payload,
    };

    return service
      .post(url, p)
      .then((result) => {
        commit("setDeal", result);
        if (result.web.content || result.data_sharing) {
          commit("setDealStrings", {
            content: result.web.content,
            dataSharing: result.data_sharing,
          });
          commit("setDealOffering", result.subscription.offering);
        }
        if (payload.domain) {
          commit("setCheckedDomain", payload.domain);
        }
        if (
          !state.register.isPreRegToken &&
          !state.register.isSavedPreRegistration
        ) {
          router.push("/redeem").catch((err) => {});
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  },

  async getSavedPreRegistration({ commit, state }, payload) {
    const url = `/getPreRegistration?tokenId=${payload.tokenId}`;

    let result;
    try {
      result = await service.get(url);

      if (result) {
        commit("setSavedPreRegistration", result);
        commit("setSavedPreRegistrationError", false);
      } else {
        commit("setSavedPreRegistrationError", true);
      }
    } catch (err) {
      console.log(err);
    }

    return result;
  },

  async verifyCode({ commit, state }, code) {
    const url = "/verifyRedemptionCode";

    const deal_id = state.dealId;

    const payload = {
      ...state.analytics,
      deal_id,
      code,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit("setCode", result.code);
        commit("setDeal", result.deal);
        if (result.deal.web?.content || result.deal.data_sharing) {
          commit("setDealStrings", {
            content: result.deal.web.content,
            dataSharing: result.deal.data_sharing,
          });
        }
        return result;
      })
      .catch((err) => {
        // console.log((err);
        return;
      });
  },
  async vcesGenerateIFrameUrl({ commit, state }) {
    commit("setVcesFrameUrl", null);
    const url = "/vcesGenerateIFrameUrl";
    const payload = {
      ...state.analytics,
      lang: state.selectedLocale.key,
    };

    return service
      .post(url, payload)
      .then((result) => {
        if (result.success) {
          commit("setVcesFrameUrl", result.frameData.URL);
          commit("setVcesCorrelationId", result.frameData.correlationId);
        } else {
          if (result.error) {
            console.error(err);
          } else {
            console.error(
              "Method call to vcesGenerateIFrameUrl returned failure"
            );
          }
        }

        return result;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  async vcesValidateCid({ commit, state }) {
    const url = "/vcesValidateCid";
    const payload = {
      ...state.analytics,
      correlation_id: state.redeem.vces.correlationId,
    };
    console.log("vcesValidateCid", payload);
    commit("setVcesGranted", false);
    const r = await service.post(url, payload);
    console.log("vcesValidateCid result", r);
    try {
      const result = await service.post(url, payload);

      if (result.success) {
        let isEligible =
          result?.myWithUUrl != null &&
          result?.myWithUUrl.includes(state.redeem.vces.correlationId);
        
        commit("setVcesGranted", isEligible);
      } else {
        if (result.error) {
          const errorDetail = subs.vcesErrorCodes.find(
            (error) => error.statusCode === result.error.statusCode
          );
          const userFriendlyMessage = errorDetail
            ? errorDetail.details
            : "An unexpected error occurred.";

          console.error(userFriendlyMessage, result.error);
          commit("setVcesError", userFriendlyMessage);
          // commit("setError", userFriendlyMessage);
        } else {
          const errorMsg = "Method call to vcesValidateCid returned failure";
          console.error(errorMsg);
          commit("setVcesError", errorMsg);
        }
      }

      return result;
    } catch (err) {
      console.error(err);
      commit("setError", err.message || "An unknown error occurred.");
    }
  },
  async vcesNotifyRedeemed({ commit, state }) {
    const url = "/vcesNotifyRedeemed";

    var userId;
    
    // set the user id, if the user is coming via the mobile then use appflow id
    if(state.redeem.fromApp) {
      // this is the user id in the user object in firestore
      userId = state.redeem.appFlowUserId;
    } else {
      // this is the firebase id in the user object in firestore
      userId = state.register.user.firebase_id
    }
    // TODO - why are they using different user ids for the same user?

    const payload = {
      ...state.analytics, 
      uid: userId,
      correlation_id: state.redeem.vces.correlationId,
    };

    return service
      .post(url, payload)
      .then((result) => {
        if (result.success) {
          // do something with the error? This is not an error case
          // whats happening here?
          console.log(
            "Method call to vcesNotifyRedeemed returned success",
            result
          );

          // do something with the error
        } else {
          if (result.error) {
            console.error(err);
          } else {
            console.error("Method call to vcesNotifyRedeemed returned failure");
          }
        }

        return result;
      })
      .catch((err) => {
        console.error(err);
        return;
      });
  },

  async register({ commit, state }, p) {
    commit("setLoading", true);

    const url = "/webLogin";

    const payload = {
      ...state.analytics,
      ...p,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit("setRegister", payload);
      })
      .catch((err) => {
        commit("setLoading", false);
        return;
      });
  },

  async createUser({ commit, state, dispatch }, p) {
    commit("setLoading", true);

    const url = "/createWebUser";

    const payload = {
      ...state.analytics,
      ...p,
    };

    if (!payload.offering) payload.offering = state.dealOffering;

    // // console.log((payload);
    return service
      .post(url, payload)
      .then((result) => {
        return dispatch("account/signIn", result, { root: true });
      })
      .catch((err) => {
        commit("setLoading", false);
        // console.log((err.response);
        if (err.response && err.response.status == 409) {
          commit("setExistingUser", true);
          commit("setUpgradeExistingAccountToSubscriber");
          commit("setEmail", p.email);
          return false;
        }
        return true;
      });
  },

  async stripeSession({}, customer) {
    const url = "/stripeSession";

    return service.post(url, { customer }).catch((err) => {
      return null;
    });
  },

  async verifyLoginCode({ commit, state, dispatch }, { payload, manage }) {
    commit("setLoading", true);

    const url = "/verifyWebLoginCode";

    const p = {
      ...state.analytics,
      ...payload,
    };

    return service
      .post(url, p)
      .then((result) => {
        if (manage) {
          commit("setUser", result.user);
          commit("setLoading", false);

          return false;
        } else {
          return dispatch("account/signIn", result, { root: true });
        }
      })
      .catch((err) => {
        commit("setLoading", false);
        return true;
      });
  },

  async webSignIn({ commit, state, getters }, email) {
    commit("setLoading", true);

    const url = "/signInWeb";

    const payload = {
      ...state.analytics,
      email,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit("setLoading", false);
        return false;
      })
      .catch((err) => {
        commit("setLoading", false);
        return true;
      });
  },

  async signIn({ commit, state, getters, dispatch }, result) {
    // console.log(result);

    if (result.signInLink) {
      return signInWithEmailLink(getAuth(), result.email, result.signInLink)
        .then((userCredential) => {
          // console.log(result, 'firebase sign in link');
          commit("setUser", result.user);

          const deal = getters.deal;
          var requiresPayment = true;
          if (getters.hasActiveSubscription) {
            requiresPayment = false;
          } else {
            if (
              deal?.subscription?.requires_payment == false ||
              deal?.subscription?.offering?.startsWith("free_")
            ) {
              requiresPayment = false;
            }
          }

          if (deal && getters.hasActiveSubscription) {
            const subscription = getters.userSubscription;
            const store = subscription.store;
            const status = subscription.status;
            const isActiveSubscription =
              status == subs.subscriptionActivationState.ACTIVE ||
              status == subs.subscriptionActivationState.NEW;
            const isPaidSubscription =
              store == subs.subsciptionPaymentHandler.APP_STORE ||
              store == subs.subsciptionPaymentHandler.PLAY_STORE ||
              store == subs.subsciptionPaymentHandler.STRIPE;

            //User already on paid subscription but deal is free so we need to communicate cancellation to them
            if (
              isActiveSubscription &&
              isPaidSubscription &&
              !requiresPayment
            ) {
              commit("setCancellationInstructions", {
                requiresCancellation: true,
                store: subscription.store,
              });
            }
          }

          if (requiresPayment) {
            router.push("/subscribe").catch((err) => {});
          } else {
            router.push("/download").catch((err) => {});
          }

          if (getters.isVcesDeal) {
            dispatch("account/vcesNotifyRedeemed", {}, { root: true });
          }

          setTimeout(() => {
            dispatch("account/clearExistingUser", {}, { root: true });
          }, 2000);

          return false;
        })
        .catch((err) => {
          commit("setLoading", false);
          return true;
        });
    } else {
      commit("setLoading", false);
      return true;
    }
  },

  async downloadLink({ commit, state }) {
    commit("setLoading", true);

    const url = "/downloadLink";

    const payload = {
      name: state.register.name,
      email: state.register.email,
      createCode: false,
    };

    // // console.log((payload);

    return service
      .post(url, payload)
      .then((result) => {
        // console.log(result);
        commit("setDownload", result);
        return;
      })
      .catch((err) => {
        commit("setLoading", false);
        return;
      });
  },

  async sendText({ dispatch, commit, state }, p) {
    commit("setLoading", true);

    const url = "/sendText";

    const payload = {
      ...state.analytics,
      ...p,
    };

    return service
      .post(url, payload)
      .then((result) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  },

  async checkoutSession({ dispatch, commit, state }, priceId) {
    commit("setLoading", true);

    const url = "/checkoutSession";

    const payload = {
      ...state.analytics,
      priceId,
      dealId: state.register.deal_id,
      uid: state.register.uid,
      user_id: state.register.user_id,
      email: state.register.email,
    };

    return service.post(url, payload).then((result) => {
      return result.sessionId;
    });
  },

  async subscribe({ dispatch, commit, state }, subscribePayload) {
    const url = "/createStripeCustomerAndSubscription";

    const payload = {
      ...state.analytics,
      ...subscribePayload,
      deal_id: state.register.deal_id,
      uid: state.register.uid,
      user_id: state.register.user_id,
      email: state.register.email,
      name: state.register.name,
    };

    return service.post(url, payload).then((result) => {
      commit("setStripe", {
        paymentMethodId: payload.paymentMethodId,
        priceId: payload.priceId,
        ...result,
      });
      return result;
    });
  },

  async upgrade({ dispatch, commit, state }, upgradePayload) {
    const url = "/createStripeCustomerAndSubscription";

    const payload = {
      ...state.analytics,
      ...upgradePayload,
      deal_id: state.register.deal_id,
      uid: state.register.uid,
      user_id: state.register.user_id,
      email: state.register.email,
      name: state.register.name,
    };

    return service.post(url, payload).then((result) => {
      commit("setStripe", {
        paymentMethodId: payload.paymentMethodId,
        priceId: payload.priceId,
        ...result,
      });
      return result;
    });
  },

  async updateCurrency({ state, commit }) {
    try {
      const response = await fetch(
        `https://ipapi.co/json/?key=${process.env.VUE_APP_IP_API_KEY}`
      );
      if (!response.ok) {
        console.error(
          "Failed to fetch location data",
          response.status,
          response.statusText
        );
      }
      const location = await response.json();

      commit("setLocation", location);

      if (location && location.currency) {
        const c = location.currency;
        if (["USD", "GBP", "EUR"].includes(c)) {
          const payload = {
            didUpdate: true,
            country: location.country,
            currency: c,
          };
          commit("setCurrency", payload);
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateSubscriptionStatus({ dispatch, commit, state }) {
    const url =
      "/getSubscriptionStatus?firebaseId=" +
      encodeURIComponent(state.register.user.firebase_id);

    return service.get(url).then((result) => {
      if (result.subscription) {
        commit("setSubscription", result.subscription);
      }
    });
  },
  setEmail({ commit }, email) {},
  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
  setShowReturnToAppMessage({ commit }, show) {
    commit("setShowReturnToAppMessage", show);
  },
  setInitLoading({ commit }, loading) {
    commit("setInitLoading", loading);
  },
  clearExistingUser({ commit }) {
    commit("setExistingUser", false);
  },
  logout({ commit }) {
    commit("clearUser");
  },
  clearDeal({ commit }) {
    commit("clearDeal");
  },
  switchLocale({ commit }, locale) {
    let siteStrings = getStringsForLocale(locale);
    commit("setLocale", locale);
    commit("setStrings", siteStrings);
  },
  async registerWithToken({ commit, state, dispatch }, payload) {
    commit("setLoading", true);
    commit("setTokenRegistrationError", false);

    if (!payload.token) {
      commit("setTokenRegistrationError", true);
      commit("setLoading", false);
      return;
    }

    let registrationPayload = {
      name: null,
      email: null,
      deal_id: null,
      agreed_terms: false,
      block_marketing: true,
      locale: state.selectedLocale.key,
    };
    let decoded = {};

    if (payload.isJwt) {
      //TODO - JWT verify + extract fName lName email etc...
    } else {
      //Assume base 64 - decode
      let decodedParams;
      try {
        decodedParams = base64.decode(payload.token);
      } catch (e) {
        commit("setTokenRegistrationError", true);
        commit("setLoading", false);
        return;
      }

      if (!decodedParams) {
        commit("setTokenRegistrationError", true);
        commit("setLoading", false);
        return;
      }

      decoded = Object.fromEntries(
        new URLSearchParams(decodedParams).entries()
      );

      if (
        !decoded ||
        !decoded.firstName ||
        !decoded.lastName ||
        !decoded.email ||
        !isEmail(decoded.email)
      ) {
        commit("setTokenRegistrationError", true);
        commit("setLoading", false);
        return;
      }
    }

    //Get deal & create user
    registrationPayload.name = `${decoded.firstName} ${decoded.lastName}`;
    registrationPayload.email = decoded.email;
    registrationPayload.deal_id = decoded.dealId;

    if (decoded.agreedTerms) {
      registrationPayload.agreed_terms = decoded.agreedTerms === "true";
    }

    if (decoded.agreedMarketing) {
      registrationPayload.block_marketing = !(
        decoded.agreedMarketing === "true"
      );
    }

    await dispatch("getDeal", { dealId: registrationPayload.deal_id });

    if (payload.path === "/parkrun") {
      commit("setParkrunRegistrationData", registrationPayload);
      router.push("/").catch((err) => {});
    } else {
      commit("setTokenRegistration", true);
      await dispatch("createUser", registrationPayload);
      router.push("/download").catch((err) => {});
    }
  },
  async registerWithTokenId({ commit, state, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSavedPreRegistrationError", false);

    if (!payload.tokenId) {
      commit("setSavedPreRegistrationError", true);
      commit("setLoading", false);
      return;
    }

    let registrationPayload = {
      name: null,
      email: null,
      deal_id: null,
      agreed_terms: true,
      block_marketing: true,
      locale: state.selectedLocale.key,
    };

    let tokenRegistration;
    try {
      const response = await dispatch("getSavedPreRegistration", payload);
      tokenRegistration = response.token;
    } catch (e) {
      commit("setSavedPreRegistrationError", true);
      commit("setLoading", false);
      return;
    }

    if (!tokenRegistration) {
      commit("setSavedPreRegistrationError", true);
      commit("setLoading", false);
      return;
    }

    // Get deal & create user
    registrationPayload.name = `${tokenRegistration.fname} ${tokenRegistration.lname}`;
    registrationPayload.email = tokenRegistration.email;
    registrationPayload.deal_id = tokenRegistration.deal_id;

    commit("setSavedPreRegistration", true);
    await dispatch("getDeal", { dealId: registrationPayload.deal_id });
    await dispatch("createUser", registrationPayload);

    router.push("/download").catch((err) => {});
  },
};

const mutations = {
  setLocation(state, location) {
    state.analytics.location = {
      ip: location.ip,
      city: location.city,
      region: location.region,
      region_code: location.region_code,
      country: location.country,
      country_code: location.country_code,
      postal: location.postal,
      timezone: location.timezone,
      currency: location.currency,
    };
  },
  setLoading(state, loading) {
    state.loading = loading;
    state.error = null;
  },
  setShowReturnToAppMessage(state, value) {
    state.redeem.showReturnToAppMessage = value;
  },
  setInitLoading(state, loading) {
    state.initLoading = loading;
    state.error = null;
  },
  setTimeout(state, branchMatchId) {
    state.redeem.adblock = branchMatchId && state.redeem.data == null;
    if (state.initLoading) {
      state.initLoading = false;
    }
  },
  setBranchData(state, data) {
    state.redeem.data = data;
    state.redeem.adblock = false;
    if (
      data &&
      data.data_parsed != null &&
      data.data_parsed["+clicked_branch_link"] == true
    ) {
      state.redeem.verifiedAt = Date.now();
    }
  },
  setCode(state, code) {
    state.redeem.code = code;
  },
  setDeal(state, deal) {
    state.redeem.deal = deal;
    state.loading = false;
    state.initLoading = false;
    state.redeem.verifiedAt = Date.now();
  },
  setIsReferringLink(state, value) {
    state.redeem.referringLink = value;
  },
  clearDeal(state) {
    state.redeem.deal = null;
  },
  setParkrunRegistrationData(state, register) {
    state.register.email = register.email;
    state.register.name = register.name;
    state.register.deal_id = register.deal_id;
    state.register.agreed_terms = register.agreed_terms;
    state.register.block_marketing = register.block_marketing;
  },
  clearUser(state) {
    state.register.user = null;
  },
  setUser(state, user) {
    // console.log(('set user', user);
    state.register.user_id = user.user_id;
    state.register.uid = user.firebase_id;
    state.register.email = user.email;
    state.register.name = user.name;
    state.register.deal_id = user.deal_id;
    state.register.user = user;

    state.analytics.user_id = user.user_id;
    state.analytics.email = user.email;

    state.stripe = null;

    if (window.Intercom) {
      window.Intercom("update", { user_id: user.user_id, email: user.email });
    }
  },
  setDownload(state, resp) {
    state.download.link = resp.link;
    state.loading = false;
  },
  setEmail(state, email) {
    state.register.email = email;
  },
  setError(state, error) {
    state.error = error;
    state.loading = false;
  },
  setStripe(state, stripe) {
    state.stripe = stripe;
    // // console.log((stripe, 'stripe');
  },
  setExistingUser(state, val) {
    state.register.existingUser = val;
  },
  setCanUpgradeSubscription(state, val) {
    state.upgrade.canUpgrade = val;
  },
  setCurrentSubscriptionProduct(state, val) {
    state.upgrade.currentProduct = val;
  },
  setCurrency(state, payload) {
    state.currency = payload;
  },
  setSubscription(state, payload) {
    state.register.user.subscription = payload;
  },
  setCheckedDomain(state, domain) {
    state.checkedDomain = domain;
  },
  setUpgradeExistingAccountToSubscriber(state) {
    state.upgrade.existingAccountToSubscriber = true;
  },
  setExistingDealWasBest(state) {
    state.upgrade.existingDealWasBest = true;
  },
  setPaidToFreeBlock(state) {
    state.upgrade.paidToFreeBlock = true;
  },
  setLocale(state, locale) {
    state.selectedLocale = locale;
  },
  setStrings(state, strings) {
    state.siteStrings = strings;
  },
  setDealStrings(state, payload) {
    state.dealStrings = payload;
  },
  setDealOffering(state, payload) {
    state.dealOffering = payload;
  },
  setDealId(state, dealId) {
    state.dealId = dealId;
  },
  setTokenRegistration(state, value) {
    state.register.isPreRegToken = value;
  },
  setTokenRegistrationError(state, value) {
    state.register.tokenVerificationError = value;
  },
  setSavedPreRegistration(state, value) {
    state.register.isSavedPreRegistration = value;
  },
  setSavedPreRegistrationError(state, value) {
    state.register.savedPreRegistrationError = value;
  },
  setCancellationInstructions(state, payload) {
    state.cancellationInstructions = payload;
  },
  setVcesFrameUrl(state, value) {
    state.redeem.vces.frameUrl = value;
  },
  setVcesCorrelationId(state, value) {
    state.redeem.vces.correlationId = value;
  },
  setVcesGranted(state, value) {
    state.redeem.vces.granted = value;
  },
  setVcesError(state, value) {
    state.redeem.vces.error = value;
  },
  setFromApp(state, value) {
    state.redeem.fromApp = value;
  },
};

const getters = {
  hasUser: (state) => {
    return state.register.uid != null && state.register.user_id != null;
  },
  existingUser: (state) => {
    return state.register.existingUser;
  },
  originalLink: (state) => {
    // return 'https://app.withutraining.com/e/tiKAKSuOCgb';
    var data = state.redeem.data;
    if (data && data.data_parsed && data.data_parsed["~referring_link"]) {
      var rl = data.data_parsed["~referring_link"];
      return rl.split("?")[0];
    }
    return null;
  },
  isValid: (state) => {
    if (state.redeem && state.redeem.verifiedAt) {
      let minutes = 30;
      let minutesInMs = minutes * 60 * 1000;
      var minutesAgo = Date.now() - minutesInMs;
      const dt = new Date(minutesAgo).getTime();

      if (state.redeem.verifiedAt > dt) {
        return true;
      }
    }
    return false;
  },
  isVcesDeal: (state, getters) => {
    return getters.hasDeal && getters.deal.is_vces;
  },
  isVcesGranted: (state, getters) => {
    return getters.hasDeal && getters.deal.is_vces && state.redeem.vces.granted;
  },
  getVcesFrameUrl: (state, getters) => {
    return state.redeem.vces.frameUrl;
  },
  getVcesError: (state, getters) => {
    return state.redeem.vces.error;
  },
  getFromApp: (state, getters) => {
    return state.redeem.fromApp;
  },
  getShowReturnToAppMessage: (state, getters) => {
    return state.redeem.showReturnToAppMessage;
  },
  offering: (state, getters) => {
    if (getters.isValid) {
      if (
        state.redeem.deal &&
        state.redeem.deal.subscription &&
        state.redeem.deal.subscription.offering
      ) {
        return state.redeem.deal.subscription.offering;
      } else if (
        state.redeem.data &&
        state.redeem.data.data_parsed &&
        state.redeem.data.data_parsed["offering"]
      ) {
        return state.redeem.data.data_parsed["offering"];
      }
    }
    return null;
  },
  deal: (state, getters) => {
    var redeem = state.redeem;
    if (!redeem.deal) {
      return null;
    }

    if (getters.isValid) {
      return redeem.deal;
    }
    return null;
  },
  hasDeal: (state, getters) => {
    return getters.deal != null;
  },
  canRedeemCode: (state, getters) => {
    return !(getters.offering || getters.hasDeal);
  },
  freeTrial: (state, getters) => {
    var str;
    if (state.redeem.deal && state.redeem.deal.subscription) {
      var subscription = state.redeem.deal.subscription;
      if (subscription.trial_days) {
        str = getters.daysToTime(subscription.trial_days);
      } else if (subscription.trial_days == 0) {
        return null;
      }
    }
    if (!str && getters.offering) {
      str = getters.offeringToTime(getters.offering);
    }

    if (!str) {
      str = "14 days";
    }

    return str;
  },
  offeringToTime: (state, getters) => (offering) => {
    if (offering == "free_daily") {
      return "1 " + getStringForKey(state.selectedLocale, "day");
    } else if (offering == "free_weekly") {
      return "7 " + getStringForKey(state.selectedLocale, "days");
    } else if (offering == "free_monthly") {
      return "1 " + getStringForKey(state.selectedLocale, "months");
    } else if (offering == "free_two_month") {
      return "2 " + getStringForKey(state.selectedLocale, "monthsPlural");
    } else if (offering == "free_three_month") {
      return "3 " + getStringForKey(state.selectedLocale, "monthsPlural");
    } else if (offering == "free_six_month") {
      return "6 " + getStringForKey(state.selectedLocale, "monthsPlural");
    } else if (offering == "free_yearly") {
      return "1 " + getStringForKey(state.selectedLocale, "years");
    } else if (offering == "free_lifetime") {
      return getStringForKey(state.selectedLocale, "lifetime");
    } else {
      return null;
    }
  },
  daysToTime: () => (days) => {
    if (days == 1) {
      return `1 ` + getStringForKey(state.selectedLocale, "day");
    } else if (days <= 14) {
      return `${days} ${getStringForKey(state.selectedLocale, "days")}`;
    } else if (days <= 31) {
      return `1 ${getStringForKey(state.selectedLocale, "month")}`;
    } else if (days <= 62) {
      return `2 ${getStringForKey(state.selectedLocale, "monthsPlural")}`;
    } else if (days <= 93) {
      return `3 ${getStringForKey(state.selectedLocale, "monthsPlural")}`;
    } else if (days <= 186) {
      return `6 ${getStringForKey(state.selectedLocale, "monthsPlural")}`;
    } else if (days <= 365) {
      return `1 ${getStringForKey(state.selectedLocale, "year")}`;
    } else {
      return null;
    }
  },

  products: (state, getters) => {
    const products = getProducts();
    let productsFinal = _.cloneDeep(products);

    function clearPricingMatchingCurrency(currency) {
      //Remove any existing products that match the new pricing currency
      //The subscription page matches the product to the currency so they must be unique
      //This also removes monthly AND annual as only 1 may be set on the deal
      productsFinal[0].prices = productsFinal[0].prices.filter(
        (p) => p.currency != currency
      );
      productsFinal[1].prices = productsFinal[1].prices.filter(
        (p) => p.currency != currency
      );
    }

    //Override products if on deal
    if (getters.hasDeal) {
      const deal = getters.deal;
      let pricing = deal?.stripe_pricing;
      if (pricing) {
        if (pricing.annual != null) {
          clearPricingMatchingCurrency(pricing.annual.currency);
          productsFinal[0].prices.push(pricing.annual);
        }
        if (pricing.monthly != null) {
          clearPricingMatchingCurrency(pricing.monthly.currency);
          productsFinal[1].prices.push(pricing.monthly);
        }
      }
    }

    //One off payment option
    if (getters.hasDeal) {
      const deal = getters.deal;
      const stripePricing = deal?.subscription?.stripe_pricing;
      if (stripePricing) {
        productsFinal = [
          {
            product_id: stripePricing.product_id,
            term: stripePricing.term,
            interval_count: stripePricing.interval_count,
            interval: stripePricing.interval,
            prices: [
              {
                price_id: stripePricing.price_id,
                currency: stripePricing.currency,
                symbol: stripePricing.symbol,
                price: stripePricing.price,
                subunit: stripePricing.subunit,
              },
            ],
          },
        ];
      }
    }

    return productsFinal;
  },
  userSubscription: (state) => {
    var user = state.register.user;
    if (user) {
      return user.subscription;
    }
  },
  hasActiveSubscription: (state, getters) => {
    return getters.userSubscription?.has_access;
  },
  canUpgradeSubscription: (state, getters) => {
    var subscription = getters.userSubscription;
    return subscription && subscription.store == "stripe";
  },
  isBetterDeal: (state, getters) => {
    var subscription = getters.userSubscription;
    var currentPrice = 0;
    if (subscription.price) {
      currentPrice = subscription.price;
    }

    if (subscription.discount_percent != null) {
      currentPrice -= currentPrice * (subscription.discount_percent / 100);
    }

    var product =
      subscription.pricing_period == "monthly"
        ? getters.products[1]
        : getters.products[0];

    var newPrice = product.prices.find((x) => x.currency == getters.currency)
      .price;

    if (getters.deal?.subscription.discount_percent != null) {
      newPrice -= newPrice * (getters.deal.subscription.discount_percent / 100);
    }

    return newPrice < currentPrice;
  },
  getCurrentlySubscribedProduct: (state, getters) => {
    let user = state.register.user;
    var subscription = null;
    if (user) {
      subscription = user.subscription;
    }

    if (subscription == null) {
      return;
    }

    let priceId = subscription.price_id;

    if (priceId) {
      var product;
      var price;

      getters.products.every((p) => {
        price = p.prices.find((x) => x.price_id == priceId);
        if (price) {
          product = p;
          return false;
        }
        return true;
      });

      return {
        term: product.term,
        currency: price.currency,
        symbol: price.symbol,
        price: price.price,
        subunit: price.subunit,
      };
    }
  },
  upgrade: (state) => state.upgrade,
  currency: (state) => state.currency.currency,
  country: (state) => state.currency.country,
  firstName: (state) => {
    if (state.register) {
      if (
        state.register.name &&
        state.register.name != null &&
        state.register.name != ""
      ) {
        var chunks = state.register.name.split(/\s+/);
        if (chunks[0]) {
          return chunks[0];
        }
      }
    }
    return null;
  },
  fullName: (state) => state?.register?.name,
  isReferral: (state) => state.redeem.referringLink,
  getCheckedDomain: (state) => state.checkedDomain,
  existingAccountToSubscriber: (state) =>
    state.upgrade.existingAccountToSubscriber,
  existingDealWasBest: (state) => state.upgrade.existingDealWasBest,
  paidToFreeUpgradeBlock: (state) => state.upgrade.paidToFreeBlock,
  getSelectedLocale: (state) => state.selectedLocale,
  getDealStrings: (state) => state.dealStrings,
  enforceCurrencyOnDeal(state, getters) {
    if (getters.hasDeal) {
      const deal = getters.deal;
      let stripePricing = deal?.stripe_pricing;
      if (stripePricing) {
        return pricing?.annual?.currency || pricing?.monthly?.currency;
      }
      //One off payment
      stripePricing = deal?.subscription?.stripe_pricing;
      if (stripePricing) {
        return stripePricing.currency;
      }
    }
  },
  isPreRegToken: (state) => state.register.isPreRegToken,
  tokenRegistrationError: (state) => state.register.tokenVerificationError,
  isSavedPreRegistration: (state) => state.register.isSavedPreRegistration,
  savedPreRegistrationError: (state) =>
    state.register.savedPreRegistrationError,
  showLoading: (state) => state.loading,
  getCancellationInstructions: (state) => state.cancellationInstructions,
  register: (state) => state.register,
  fromApp: (state) => state.redeem.fromApp,
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
