<template>
  <div
    v-if="isMobile"
    class="d-flex flex-row"
    style="height: 150px; width: 100%"
    :style="
      isDark
        ? 'background-color: rgba(13, 0, 45, 0.3)'
        : 'background-color: rgba(255,255,255,.5)'
    "
  >
    <div
      v-if="isMobile"
      class="d-flex flex-row mt-14"
      style="height: 80px; width: 100%"
    >
      <div class="mx-auto my-auto" style="width: 40%">
        <v-img
          class="mx-auto"
          :src="
            isDark
              ? require('@/assets/withu-logo-white.png')
              : require('@/assets/withu-logo-black.png')
          "
          max-height="70px"
          max-width="80%"
          contain
        ></v-img>
      </div>
      <v-divider v-if="!logoDisabled  && logoUrl != null" class="mx-auto" vertical :color="`#${themeColor}`"></v-divider>
      <div v-if="!logoDisabled && logoUrl != null" class="mx-auto my-auto" style="width: 40%">
        <v-img
          class="mx-auto"
          :src="logoUrl"
          max-height="70px"
          max-width="80%"
          contain
        ></v-img>
      </div>
    </div>
  </div>
  <div
    v-else
    class="d-flex flex-row mt-14 mx-auto"
    style="height: 80px; width: 80%; max-width: 400px"
  >
    <div v-if="!logoDisabled  && logoUrl != null" class="mx-auto my-auto" style="width: 40%">
      <v-img
        class="mx-auto"
        :src="logoUrl"
        max-height="70px"
        max-width="80%"
        contain
      ></v-img>
    </div>
    <v-divider v-if="!logoDisabled && logoUrl != null" class="mx-auto" vertical></v-divider>
    <div class="mx-auto my-auto" style="width: 40%">
      <v-img
        class="mx-auto"
        src="@/assets/withu-logo-black.png"
        max-height="70px"
        max-width="80%"
        contain
      ></v-img>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  props: {
    isMobile: Boolean,
    logoUrl: String,
    logoDisabled: Boolean,
    isDark: Boolean,
    themeColor: String,
  },
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    unlockText() {},
  },
};
</script>

<style scoped>
</style>