<template>
  <div>
    <!-- CODE INPUT (NO OFFER) -->
    <div>
      <!-- hack to trigger scroll to top when deal gets found -->
      <div style="display: none">{{ latestDealId }}</div>
      <!-- HEADER -->
      <div v-if="!existingUser">
        <!-- NO OFFER DEFAULT HEADER -->
        <div v-if="!isReferralLink && !hasDeal">
          <div v-if="hasPartnershipBranding">
            <partner-header class="partner-header" :is-dark="false"
              :logo-url="hasDeal ? offerPageLogo : customRedemptionLogo" :logoDisabled="logoDisabled"
              :theme-color="themeColor" :is-mobile="true"></partner-header>
          </div>
          <v-img class="redeem-header" :position="hasPartnershipBranding ? 'center' : 'top right'" height="600"
            :src="mainImage.src" :lazy-src="mainImage.lazySrc" :eager="true" />
          <div :style="`margin-top: -${mobileTripleOffset}px`">
            <div class="mx-4 my-8" style="
                position: relative;
                height: 94px;
                width: 205px;
                white-space: pre-line;
              ">
              <div class="primary--text text-h6 font-weight-bold" v-if="!hasPartnershipBranding">
                {{ title }}
              </div>
              <div class="secondaryGrey--text text-body1 font-weight-bold" v-if="!hasPartnershipBranding">
                {{ subtitle }}
              </div>
            </div>
            <v-img class="mx-auto" position="center" src="@/assets/mobile-triple.png"
              lazy-src="@/assets/mobile-triple-lo.png" :width="mobileTripleWidth" :max-width="mobileTripleWidth"
              :eager="true" contain></v-img>
          </div>
        </div>
        <div v-else-if="isReferralLink">
          <!-- OFFER FOUND THROUGH REFERRAL -->
          <deal-container :logo="logo" :existingUser="existingUser" :hideAccount="hideAccount" :isVcesDeal="isVcesDeal"
            :isVcesGranted="isVcesGranted" :fromApp="fromApp" :showReturnToAppMessage="showReturnToAppMessage" :stringForKey="stringForKey"></deal-container>
          <div v-if="!existingUser" style="white-space: pre-line">
            <div class="
                my-8
                py-4
                grey-fill
                primary--text
                text-h6 text-center
                font-weight-bold
              ">
              {{ stringForKey("audioFitnessApp") }}
            </div>
            <v-img class="mx-auto" position="center" :src="localizedImage
              " lazy-src="@/assets/mobile-triple-lo.png" :width="mobileTripleWidth" :max-width="mobileTripleWidth"
              :eager="true" contain></v-img>
            <div class="
                my-8
                py-4
                primary--text
                text-body1 text-center
                font-weight-bold
              ">
              {{ stringForKey("soundedSoGood") }}
            </div>
          </div>
        </div>
        <div v-if="!hasPartnershipBranding && logo != null && !hasDeal && !logoDisabled">
          <div class="d-flex flex-column mt-8 pt-2 pb-4" style="width: 100%">
            <div class="mx-auto text-center white--text text-caption" style="height: 30%">
              {{ stringForKey("partnered") }}
            </div>
            <v-img class="mx-auto" :src="logo" max-height="120px" min-height="60px" :eager="true" contain />
          </div>
        </div>

        <div v-if="!hasDeal && !isReferralLink">
          <div id="user-input"></div>
          <!-- <v-divider v-if="logo == null" class="mx-8"></v-divider> -->
          <!-- ACCOUNT/REDEEM -->
          <div v-if="canRedeemCode && canEnterCode">
            <redeem-code class="mt-6 px-8" v-if="canRedeemCode && canEnterCode" :enteringCode="enteringCode"
              v-on:toggle="toggleEnteringCode" />
          </div>
          <div v-else-if="!hideAccount">
            <account class="mt-6 px-8"></account>
          </div>
        </div>
        <div v-if="!hasDeal || (isReferralLink && !existingUser)" class="mt-8">
          <!-- DISCIPLINES -->
          <div class="grey-fill py-4">
            <div class="primary-text text-h5 text-center mt-8">
              {{ stringForKey("disciplines") }}
            </div>
            <div class="primary-text caption text-center mt-4">
              {{ stringForKey("workoutsRange") }}
            </div>
            <v-img 
              :src="disciplineImage['1x']" 
              :srcset="`${disciplineImage['2x']} 2x, ${disciplineImage['3x']} 3x`" 
              
            />
          </div>
          <!-- BULLETS 1 -->
          <div>
            <div class="d-flex flex-wrap mt-8 justify-center px-8">
              <div v-for="bullet in bulletsFirst" :key="bullet.id">
                <div class="mt2 bullet-top">
                  <div class="bullet-top-container justify-center mx-auto">
                    <div style="height: 55px">
                      <v-img class="mx-auto" contain :src="require(`@/assets/${bullet.icon}`)"
                        :lazy-src="require(`@/assets/${bullet.iconLo}`)" width="50px" position="center" :eager="true" />
                    </div>
                    <div class="px-4 mt-2 text-caption">
                      {{ bullet.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- BULLETS 2
          <div class="grey-fill mt-8">
            <div class="d-flex flex-column justify-center pt-8">
              <div v-for="bullet in bulletsSecond" :key="bullet.id">
                <v-img
                  class="mx-auto"
                  contain
                  :src="require(`@/assets/${bullet.icon}`)"
                  :lazy-src="require(`@/assets/${bullet.iconLo}`)"
                  width="50px"
                  position="center"
                  :eager="true"
                />
                <div class="mt-2 mb-8 px-4 text-caption text-center">
                  {{ bullet.text }}
                </div>
              </div>
            </div>
          </div> -->
          <!-- TESTIMONIALS -->
          <div class="grey-fill pt-8">
            <div class="primary--text text-center font-weight-bold text-h6 mb-6">
              {{ stringForKey("seeWhatUsers") }}
            </div>
            <testimonials></testimonials>
          </div>
          <!-- FOOTER -->
          <div class="rating-container">
            <v-img class="mx-auto" contain src="@/assets/mobile-lifestyle.jpg" lazy-src="@/assets/mobile-lifestyle-lo.jpg"
              width="100%" position="center" :eager="true" />
            <div class="stars">
              <v-img class="mx-auto" contain src="@/assets/stars.png" lazy-src="@/assets/stars-lo.png" width="120px"
                position="center" :eager="true" />
              <div class="mx-auto mt-2 font-weight-bold text-caption">
                {{ stringForKey("rating") }}
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex justify-center purple-box align-center">
              <v-icon large color="white" class="flex-grow-1">mdi-chevron-up</v-icon>
              <a href="#user-input" class="flex-grow-2 white--text font-weight-bold text-center">{{
                stringForKey("freeTrial") }}</a>
              <div class="flex-grow-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OFFER FOUND -->
    <deal-container class="mb-16" v-if="(hasDeal && !isReferralLink) || existingUser" :logo="offerPageLogo"
      :existingUser="existingUser" :logoDisabled="logoDisabled" :hideAccount="hideAccount" :isVcesDeal="isVcesDeal"
      :isVcesGranted="isVcesGranted"></deal-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import redeem from "../redeem";
import testimonials from "./testimonials";
import dealContainer from "./deal-container";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  components: { testimonials, dealContainer },
  mixins: [redeem],
  data: () => ({
    mobileTripleWidth: 100,
    mobileTripleOffset: 100,
    dealId: "",
  }),
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),

    bulletsFirst() {
      return [
        {
          icon: "bullet-touch.png",
          iconLo: "bullet-touch-lo.png",
          text: getStringForKey(this.selectedLocale, "onDemandWorkouts"),
        },
        {
          icon: "bullet-coach.png",
          iconLo: "bullet-coach-lo.png",
          text: getStringForKey(this.selectedLocale, "coaches"),
        },
        {
          icon: "bullet-programme.png",
          iconLo: "bullet-programme-lo.png",
          text: getStringForKey(this.selectedLocale, "programmes"),
        },
        {
          icon: "bullet-time.png",
          iconLo: "bullet-time-lo.png",
          text: getStringForKey(this.selectedLocale, "anytime"),
        },
      ];
    },
    title() {
      let pageKey;
      let dealText;

      console.log(`Deal ${this.hasDeal}`);
      console.log(`Redeem ${this.isRedeem}`);
      console.log(`Partnership branding ${this.hasPartnershipBranding}`);
      console.log(`Referal ${this.isReferralLink}`);

      if (this.hasDeal) {
        pageKey = "content.deal.title";
      }
      if (this.isRedeem) {
        pageKey = "content.redeem.title";
      }

      if (pageKey) {
        dealText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          pageKey
        );
      }
      return (
        dealText ||
        getStringForKey(this.selectedLocale, "audioFitnessAppMobile")
      );
    },
    subtitle() {
      let pageKey;
      let dealText;
      if (this.hasDeal) {
        pageKey = "content.deal.subtitle";
      }
      if (this.isRedeem) {
        pageKey = "content.redeem.subtitle";
      }
      if (pageKey) {
        dealText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          pageKey
        );
      }
      return dealText || getStringForKey(this.selectedLocale, "soundedSoGood");
    },
    logo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.logo"
      );
    },
    customRedemptionLogo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.redeem.logo"
      );
    },
    offerPageLogo() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.offer_page_logo"
      );
    },
    logoDisabled() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.logo_disabled"
      );
    },
    latestDealId() {
      this.dealId = this.deal?.id;
      window.scrollTo(0, 0);
      return this.dealId;
    },
    disciplineImage() {
      let baseImage = this.selectedLocale.key == 'es' ? 'disciplines-es' : 'disciplines';
      return {
        '1x': require(`@/assets/${baseImage}@1x.png`),
        '2x': require(`@/assets/${baseImage}@2x.png`),
        '3x': require(`@/assets/${baseImage}@3x.png`)
      };
    },
    disciplineAltText() {
      return this.selectedLocale.key == 'es'
        ? 'Disciplinas: Diagrama circular con ocho segmentos de diferentes colores que representan diversas disciplinas académicas. Cada segmento está etiquetado con el nombre de la disciplina en español.'
        : 'Colorful circular diagram divided into eight segments, each representing a different academic discipline. Each segment is labeled with the name of the discipline in English.';
    },
    localizedImage() {
      switch (this.selectedLocale.key) {
        case "es":
          return require("@/assets/desktop-redeem-es.png");
        case "pt":
          return require("@/assets/desktop-redeem-pt.png");
        case "en":
          return require("@/assets/desktop-redeem.png");
      }
    },
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    windowResize() {
      this.mobileTripleWidth = window.innerWidth * 0.8;
      var scale = this.mobileTripleWidth / 320;
      var h = 270 * scale;
      this.mobileTripleOffset = 0.5 * h + 170;
    },
  },
  mounted() {
    window.addEventListener("resize", this.windowResize);
    this.windowResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  },
};
</script>

<style scoped>
.partner-header {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.redeem-header {
  position: relative;
  margin-top: 0px;
  right: 0px;
  z-index: 0;
}

.bullet-top {
  width: 40vw;
  height: 150px;
  text-align: center;
}

.bullet-top-container {
  width: 150px;
}

.purple-box {
  background-color: rgba(13, 0, 45, 0.7);
  height: 50px;
}

.rating-container {
  position: relative;
  text-align: center;
  color: white;
}

.stars {
  width: 100%;
  position: absolute;
  top: 50%;
}
</style>
