import { router } from "../../../router";

const state = {
  type: null,
  message: null,
  timeout: -1,
  uploading: false,
  uploadingMessage: "",
};

const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ dispatch, commit }, error) {
    // console.log((error);
    if (error) {
      if (error.status === 401) {
        // localStorage.removeItem('user');
        // localStorage.removeItem('company');
        // axios.defaults.headers.common['Authorization'] = null;
        // dispatch('account/logout', null, { root: true });
      }
      // commit('error', error);
    }
    return error;
  },
  clear({ commit }) {
    commit("clear");
  },
  uploading({ commit }, message) {
    commit("uploading", message);
  },
  clearUploading({ commit }) {
    commit("finishedUploading");
  },
};

const mutations = {
  success(state, message) {
    state.type = "success";
    state.message = message;
    state.timeout = 6000;
  },
  error(state, error) {
    state.type = "error";

    if (error.data.message) {
      state.message = error.data.message;
    } else if (error.data.error.message) {
      state.message = error.data.error.message;
    } else if (error.data.error) {
      state.message = error.data.error;
    }
    state.timeout = -1;
  },
  clear(state) {
    state.type = null;
    state.message = null;
    state.timeout = -1;
  },
  uploading(state, messaage) {
    state.uploadingMessage = messaage;
    state.uploading = true;
  },
  finishedUploading(state) {
    state.uploading = false;
    state.uploadingMessage = "";
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
};
