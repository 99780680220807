import axios from "axios";
import Vue from "vue";
import Flag from "vue-flagpack";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { router } from "./router";
import { store } from "./store";
import Page from "@/components/page";
import Logo from "@/components/logo";
import moment from "moment";
import VueMask from "v-mask";
import loadingComponent from "@/components/loading";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import VueGtm from '@gtm-support/vue2-gtm';

export const bus = new Vue();

Vue.component("page", Page);
Vue.component("logo", Logo);
Vue.component("loading-component", loadingComponent);

Vue.prototype.moment = moment;

axios.interceptors.response.use(
    (response) => response,
    (error) => {
      store.dispatch("alert/error", error.response);
      return Promise.reject(error);
    }
);

axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_API_KEY;

Vue.config.productionTip = false;

Vue.use(VueMask);

String.prototype.toUpper = function() {
  return this.toLowerCase().replace(/^\w|\s\w/g, (letter) => letter.toUpperCase());
};

String.prototype.toUpperFirstLetter = function() {
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1));
};

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  apiVersion: "2020-08-27",
  locale: "auto",
};

Vue.use(StripePlugin, options);

Vue.use(VueGtm, {
  // id: 'GTM-TVCGGJ4',
  id: 'GTM-K97T9GWR',
  // id: process.env.VUE_APP_GTM_ID,
  defer: true,
  compatibility: true,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

Vue.use(Flag, { name: "Flag" });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");