<template>
  <v-container class="partner-container">
    <v-row no-gutters align-content="center" justify="center">
      <v-col cols="12"
        ><div class="white--text caption text-center">Partnered with</div>
        <v-img contain v-bind:src="logoPath" max-height="15" /></v-col
    ></v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  props: {
    logoPath: String,
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    partneredWith() {
      return getStringForKey(this.selectedLocale, "partnered");
    },
  },
  methods: {},
};
</script>

<style scoped>
.partner-container {
  background-color: rgba(13, 0, 45, 0.7);
  left: 0px;
  right: 0px;
  max-height: 60px;
}
</style>