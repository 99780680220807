import _ from "lodash";
import { stringsEn } from "./en";
import { stringsEs } from "./es";
import { stringsPt } from "./pt";

//Flag keys: https://flagpack.xyz/docs/development/vue
export const supportedLocales = [
  { key: "en", flag: "GB-UKM" },
  { key: "es", flag: "ES" },
  { key: "pt", flag: "PT" },
];

function browserLanguage() {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang =
    lang ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;

  let shortLang = lang;
  if (shortLang.indexOf("-") !== -1) shortLang = shortLang.split("-")[0];

  if (shortLang.indexOf("_") !== -1) shortLang = shortLang.split("_")[0];

  return shortLang;
}

function isSupportedLocale(locale) {
  return supportedLocales.find((x) => x.key == locale) != null;
}

function mapKeyToLocale(key) {
  return supportedLocales.find((x) => x.key == key);
}

export function getStartLocale() {
  let browserLocale = browserLanguage();
  if (isSupportedLocale(browserLocale)) {
    return mapKeyToLocale(browserLocale);
  }
  return supportedLocales[0];
}

export function getStringsForLocale(supportedLocale) {
  let locale = supportedLocale.key;
  switch (locale) {
    case "en":
      return stringsEn;
    case "es":
      return stringsEs;
    case "pt":
      return stringsPt;
    default:
      return stringsEn;
  }
}

export function getStringForKey(locale, key) {
  let strings = getStringsForLocale(locale);
  return strings[key] || `?? ${key} ??`;
}

export const linkType = {
  eula: "EULA",
  privacy: "PRIVACY",
};

export function getLocalisedLinks(locale, link) {
  switch (locale) {
    case "en":
      if (link === "EULA") {
        return "https://withutraining.com/eula";
      } else if (link === "PRIVACY") {
        return "https://withutraining.com/privacy";
      }
    case "es":
      if (link === "EULA") {
        return "https://withutraining.com/contrato-de-licencia-de-usuario-final-de-la-aplicacion-withu/";
      } else if (link === "PRIVACY") {
        return "https://withutraining.com/legal/politicas-de-privacidad-de-la-pagina-web-y-la-aplicacion-movil-de-withu/";
      }
    case "pt":
      if (link === "EULA") {
        return "https://withutraining.com/acordo-de-licenca-de-utilizador-final-do-aplicativo-withu-3/";
      } else if (link === "PRIVACY") {
        return "https://withutraining.com/privacy";
      }
  }
}

export function getDealStringForKey(dealStrings, supportedLocale, keys) {
  if (!dealStrings) {
    return null;
  }

  let locale = supportedLocale.key;
  const keyList = keys.split(".");

  var result;
  if (
    keyList[0] &&
    dealStrings[keyList[0]] &&
    dealStrings[keyList[0]][locale]
  ) {
    switch (keyList.length) {
      case 1:
        result = dealStrings[keyList[0]][locale];
        break;
      case 2:
        result = dealStrings[keyList[0]][locale][keyList[1]];
        break;
      case 3:
        if (dealStrings[keyList[0]][locale][keyList[1]]) {
          result = dealStrings[keyList[0]][locale][keyList[1]][keyList[2]];
        }
        break;
    }
  }

  if (result === "" || (result && result != undefined)) {
    return result;
  } else {
    return null;
  }
}
