import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMobile)?_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"150px","width":"100%"},style:(_vm.isDark
      ? 'background-color: rgba(13, 0, 45, 0.3)'
      : 'background-color: rgba(255,255,255,.5)')},[(_vm.isMobile)?_c('div',{staticClass:"d-flex flex-row mt-14",staticStyle:{"height":"80px","width":"100%"}},[_c('div',{staticClass:"mx-auto my-auto",staticStyle:{"width":"40%"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":_vm.isDark
            ? require('@/assets/withu-logo-white.png')
            : require('@/assets/withu-logo-black.png'),"max-height":"70px","max-width":"80%","contain":""}})],1),(!_vm.logoDisabled  && _vm.logoUrl != null)?_c(VDivider,{staticClass:"mx-auto",attrs:{"vertical":"","color":("#" + _vm.themeColor)}}):_vm._e(),(!_vm.logoDisabled && _vm.logoUrl != null)?_c('div',{staticClass:"mx-auto my-auto",staticStyle:{"width":"40%"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":_vm.logoUrl,"max-height":"70px","max-width":"80%","contain":""}})],1):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"d-flex flex-row mt-14 mx-auto",staticStyle:{"height":"80px","width":"80%","max-width":"400px"}},[(!_vm.logoDisabled  && _vm.logoUrl != null)?_c('div',{staticClass:"mx-auto my-auto",staticStyle:{"width":"40%"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":_vm.logoUrl,"max-height":"70px","max-width":"80%","contain":""}})],1):_vm._e(),(!_vm.logoDisabled && _vm.logoUrl != null)?_c(VDivider,{staticClass:"mx-auto",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"mx-auto my-auto",staticStyle:{"width":"40%"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("@/assets/withu-logo-black.png"),"max-height":"70px","max-width":"80%","contain":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }