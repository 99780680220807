

/**
 * @description Helper function to generate a Branch link for a user to login
 * @param {Object} branch
 * @param {String} name 
 * @param {String} email 
 * @param {String} referringLink 
 * @example ```js
 *  generateBranchLoginLink(branch, "John Doe", "john@email.com, "https://withutraining.com")
 * ```
 */
export async function generateBranchLoginLink(name, email, referringLink) {
    var data = {
        mode: "web",
        $deeplink_no_attribution: true,
        email: email,
        name: name,
    };

    if (referringLink) {
        data["~referring_link"] = referringLink;
      }

    return new Promise((resolve) => {
        branch.link(
            {
                channel: "my.withutraining",
                feature: "login",
                data: data,
            },
            function (err, link) {
                resolve(link);
            }
        );
    });

}

// TODO: Replace the global String prototype with a helper function
/**   
 * @description Helper function to convert a string to title case
 * @param {String} str
 * @example ```js
 * // returns "Hello World"
 * toUpper("hello world");
 * ``` 
 */
function toUpper(str) {
    return str.toLowerCase().replace(/^\w|\s\w/g, function(letter) {
        return letter.toUpperCase();
    });
}
// TODO: Replace the global String prototype with a helper function
// Helper function to capitalize the first letter of each word in a string
/**
 * @description Helper function to capitalize the first letter of each word in a string
 * @param {String} str 
 * @example ```js
 * // returns "Hello World"
 * toUpperFirstLetter("hello world");
 * ```

 */
function toUpperFirstLetter(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
}

export { toUpper, toUpperFirstLetter };