<template>
  <div v-if="!existingUser">
    <div style="height: 100px"></div>
    <partner-logo
      v-if="logo != null && !logoDisabled"
      :imgSrc="logo"
    ></partner-logo>
    <deal v-if="!fromApp || !showReturnToAppMessage"></deal>
    <vces
      v-if="isVcesDeal && !showReturnToAppMessage && !isVcesGranted"
      class="mt-2 px-8"
    />
    <div
      v-if="fromApp && showReturnToAppMessage"
      style="padding-left: 20px; padding-right: 20px"
    >
      <div class="overlay">
        <div class="popup mx-auto d-flex flex-column text-center px-4 mt-5">
          <br />
          <div class="popup-content">
            <v-img
              alt="WithU Logo"
              src="@/assets/withu-vertical-white.png"
              :max-height="80"
              :max-width="100"
            />
          </div>

          <p class="instruction-text">
            {{ stringForKey("tap") }}
            <span class="highlight">{{ stringForKey("done") }}</span> or
            <span class="highlight">{{ stringForKey("close") }}</span>
            {{ stringForKey("popupText") }}
          </p>
        </div>
      </div>
      <div
        class="primary--text text-h5 font-weight-bold text-center"
        style="white-space: pre-line"
      >
        {{ stringForKey("redemptionComplete") }}
      </div>
      <div class="primary--text text-h7 text-center mt-2">
        {{ stringForKey("tap") }}
        <strong>{{ stringForKey("done") }}</strong> or
        <strong>{{ stringForKey("close") }}</strong>
        {{ stringForKey("popupText") }}
      </div>
    </div>
    <account
      v-if="!hideAccount && !showReturnToAppMessage"
      class="mt-2 px-8"
    ></account>
  </div>
  <div
    v-else
    class="existing-account mx-auto d-flex flex-column text-center px-4"
    style="max-width: 450px"
  >
    <existing-user></existing-user>
  </div>
</template>

<style scoped>
.existing-account {
  margin-top: 120px;
}

.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 0, 51, 0.75);
  z-index: 1000;
}

.popup {
  background-color: #0f0033;
  z-index: 1001;
  width: 90%;
  border-radius: 12px;
  padding: 20px;
  height: 30vh;
}

.instruction-text {
  color: white;
}

.highlight {
  color: #00ffbf;
  font-weight: bold;
}

@media (max-width: 375px) {
  .popup {
    height: 38vh;
  }
}
</style>
<script>
import existingUser from "./existingUser";
import account from "./account";
import deal from "./deal";
import vces from "./vces";
import partnerLogo from "./partner-logo";
import { getStringForKey } from "@/translations/utilities";
import { mapGetters } from "vuex";

export default {
  components: {
    existingUser,
    account,
    deal,
    partnerLogo,
    vces,
  },
  props: {
    logo: String,
    logoDisabled: Boolean,
    existingUser: Boolean,
    hideAccount: Boolean,
    isVcesDeal: Boolean,
    isVcesGranted: Boolean,
    fromApp: Boolean,
    showReturnToAppMessage: Boolean,
  },
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),
  },
  mounted() {
    this.handleScroll(); // Run on initial load
  },
  watch: {
    fromApp: "handleScroll",
    showReturnToAppMessage: "handleScroll",
    isVcesGranted: "handleScroll"
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    handleScroll() {
      if (this.fromApp && this.showReturnToAppMessage && this.isVcesGranted) {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
        document.documentElement.style.overflow = "";
      }
    },
  },

  beforeDestroy() {
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
  },
};
</script>
