export const stringsEs = {
  adBlock:
    "Parece que estás intentando desbloquear una oferta especial, pero tu Adblock está activado. Por favor, desactiva tu Adblock y haz clic en: Intentar de nuevo.",
  afterTrial: "Después de que termine la prueba gratis",
  alreadyHaveSub: "¡Ya tienes una suscripción de WithU!",
  alreadyRegistered:
    "Este correo electrónico ya está registrado con una cuenta de WithU",
  annual: "Anual",
  annualy: "Anualmente",
  anytime: "Entrena cuando sea y dónde sea",
  audioFitnessApp: "La App de Audio Fitness",
  audioFitnessAppMobile: "La App de\nAudio Fitness",
  availableTo: "Sólo disponible",
  cancel: "Cancelar",
  cancelAnytime: "Cancela en cualquier momento",
  cancelAppStore:
    "Por favor, cancela tu pago en la App Store o Play Store para evitar los cargos durante el período de prueba.",
  cancelledRetry:
    "Parece que tu compra fue cancelada. ¿Quieres intentarlo de nuevo?",
  cancelStripe:
    'Para evitar los cargos durante el período de prueba, escríbenos a <a href="mailto:support@withutraining.com">support@withutraining.com</a> y nuestro equipo te ayudará a cancelar el pago por Stripe. ',
  cancelTitle: "Parece que ya tienes una suscripción pagada",
  cardDetails: "Ingresa los datos de tu tarjeta de crédito",
  choose: "Elige:",
  choosePlan: "elige tu plan",
  claim: "Pide tu oferta",
  clickLink:
    "Por favor, haz clic en el siguiente enlace para descargar WithU y activar tu suscripción;\n{{ link }}\n\nPara empezar haz clic en inicio de sesión.",
    close: 'Cerrar',
  coaches: "Instructores de renombre mundial",
  codeError:
    "Uy, lamentablemente no pudimos verificar tu código. Por favor, revisa e intenta nuevamente o contácta nuestro equipo de soporte pulsando el ícono del chat.",
  codeRedeemed: "Código canjeado",
  compareOffer: "Así se compara la nueva oferta:",
  completedNextSteps:
    "Ve a la App Store (iOS) o Play Store (Android) para descargar WithU, o si deseas una descarga directa para empezar lo antes posible, haz click en el siguiente botón.",
  confirmationEmail: "Un email de confirmación ha sido enviado a",
  congratulations: "¡Felicitaciones!",
  createAccountErrorSubtitle:
    "Por favor, intenta nuevamente y si el problema continúa contacta tu proveedor del enlace.",
  createAccountErrorTitle: "Hubo un problema al crear tu cuenta.",
  createAnAccount: "Crear una cuenta",
  creditCard: "Tarjeta de crédito",
  currentPlan: "Plan Actual",
  day: "día",
  days: "días",
  disciplines: "15 disciplinas diferentes",
  discount: "Descuento",
  done: "Hecho",
  downloadApp: "Descarga la App, luego haga inicio de sesión",
  downloadAppMobile: "Descárgate WithU",
  downloadAppMobileAndroid: "Play Store (Android)",
  downloadAppMobileIos: "App Store (iOS)",
  downloadInstructions:
    "Para descargarte WithU, dirígete a la App Store (iOS) o Play Store (Android) y busca por\n'WithU: Audio Fitness App'.",
  emailAddress: "Dirección de correo electrónico*",
  enterCardDetails: "Ingrese el número completo de la tarjeta",
  enterCode: "Ingresa el código de verificación",
  enterCodeHere: "Ingresa el código aquí",
  enterPhoneNumber: "Introduce un número de teléfono",
  existingBest: "¡Tu suscripción actual es la mejor!",
  fewMoreThings:
    "Solo necesitamos algunos datos más de ti\npara activar la oferta.",
  free: "gratis",
  freeSubscription: "una suscripción gratis",
  freeTrial: "Empieza la prueba gratis",
  fullAccess: "Acceso total a WithU",
  fullName: "Nombre completo*",
  heresOffer: "Aquí está tu oferta",
  hi: "Hola",
  intercomVisaMessage: "Ingresar el número de tarjeta de mi Visa no funciona.",
  lifetime: "De por vida",
  loginCodeError: "Hubo un error verificando el código",
  membersOnly: "sólo para miembros",
  monthly: "Mensualmente",
  months: "mes",
  monthsPlural: "meses",
  newOffer: "Nueva oferta ",
  newOfferBenefit:
    "Recibirás los nuevos beneficios una vez que el pago del úlitimo período haya finalizado.",
  noCommitment: "Cancela cuando quieras, sin compromiso.",
  offer: "Has obtenido una oferta",
  offerCompare: "La nueva oferta te da nuevos beneficios:",
  offYour: "descuento en tu suscripción",
  onDemandWorkouts: "1000 entrenamientos en demanda",
  oops: "Oops!",
  or: "o",
  partnered: "En colaboración con",
  partnership: "Acuerdo de asociación",
  popupText: "terminar de configurar tu perfil.",
  programmes: "Programas para todas las habilidades",
  purchaseCancelled: "Compra cancelada",
  qualified: "¡Tienes derecho a recibir",
  rating: "# 1 App de Audio Fitness. Reconocido Mundialmente.",
  redeem: "CANJEA",
  redeemCode: "Canjear código",
  reminder:
    "Recibe un recordatorio 7 días antes de que termine tu prueba gratis",
  seeWhatUsers: "Lee lo que opinan nuestros usuarios",
  sentCode: "Hemos enviado un código de verificación a",
  smsDelays:
    "Estamos teniendo atrasos al enviar correos electrónicos a las cuentas de: Sky, Yahoo, AOL, y Rocket. Por favor espera 30 minutos para que llegue tu correo antes de contactar el equipo de soporte.",
  smsError: "Error al enviar SMS",
  smsMessage:
    "Gracias por unirte a WithU, empecemos. Haz clic en el enlace de abajo para descargar la aplicación..",
  smsNumber:
    "Para conseguir el link que te permita descargar la app y empezar, introduce tu número de teléfono a continuación",
  smsSent: "SMS enviado",
  soundedSoGood: "El ejercicio nunca sonó tan bien.",
  startMembership: "Activa tu membresía",
  subscribed: "¡Ya tienes una suscripción con WithU!",
  subscription: "Suscripción",
  subscriptionFreeTrial: "Prueba gratis",
  tap: "Pulsa",
  terms: "Al registrarme acepto el WithU",
  termsEula: "CLUF",
  termsMarketing:
    "Selecciona esta casilla si no deseas recibir notificaciones por correo electrónico de WithU con información del progreso de tu condición física, logros, nuevas funciones, productos y más con el fin de mantenerte motivado.",
  termsPayment:
    "Requerimos tu información de pago para iniciar tu cuenta. Te recordaremos antes de cobrar tu tarjeta de crédito y tu membresía de WIthU se renovará automáticamente en (.cantidad)  hasta que canceles.",
  termsPaymentOneOff:
    "Necesitamos tu información de pago para activar tu cuenta. Se te cobrará una tarifa única de {{ amount }} por una membresía de WithU de 6 meses. No habrá renovación automática después del período de 6 meses.",
  termsPaymentTrial:
    "Requerimos los datos de cobro para la prueba gratis pero no se cobrará hasta finalizar la prueba gratuita de {{ freeTrial }}. Te recordaremos antes de cobrar tu tarjeta y tu membresía de WithU se renovará automáticamente después del período de prueba, cobrando {{ amount }} (incluyendo IVA) hasta que canceles tu suscripción. ",
  termsPrivacy: "Política de Privacidad",
  testimonial1:
    "“Nunca tenía tiempo para ir al gimnasio pero 20 minutos en la sala de tu casa, un par de veces a la semana es tan alcanzable y yo, ya veo los resultados”",
  testimonial2:
    "“He usado un montón de apps pero esta es la app para mí. Tienen entrenamientos claros, motivadores...son divertidos y tienen una gran variedad de rutinas para todos”",
  testimonial3:
    "“¡Mi familia entera se involucró en las sesiones y el programa que estamos siguiendo es perfecta para nuestro nivel. 5 de 5!”",
  testimonial4:
    "“Me encantó terminar el programa de correr de la app, es mucho mejor que cualquier otra que he usado”",
  testimonial5:
    "“Siento que es brillante tener las señales visuales y auditivas. Siento que hay un montón por descubrir de esta app, yo pago mensualmente, es una excelente relación calidad - precio”",
  testimonial6:
    "“Es como estar en una clase. Es facíl de hacer en el lugar que sea, asi que no hay excusas!”",
  thanksFor: "¡Gracias por suscribirte a WithU!",
  thanksForJoining: "Gracias por unirte a WithU,",
  then: "Luego",
  through: "via",
  toWithU: "en WithU",
  trial: "Empieza tu prueba gratis de 14 días",
  tryAgain: "Intenta nuevamente",
  unlocked: "Desbloquear mediante",
  updatedSubscription: "Hemos actualizado tu suscripción actual",
  upgradedAccount: "Gracias por actualizar tu cuenta, ya estás listo",
  validationDataSharing:
    "Por favor acepta las condiciones de intercambio de datos",
  validationEmailError:
    "Por favor, proporciona una dirección de correo electrónico válido",
  validationName: "Por favor ingresa tu nombre completo",
  validationTerms:
    "Por favor acepta nuestros términos y condiciones para crear una cuenta.",
  valivalidatonEmail: "Por favor ingresa tu correo electrónico",
  vcesCidInvalid: "Parece que la tarjeta que ingresaste no es elegible",
  vcesNotEligieable: "La tarjeta parece no ser elegible",
  vcesUrlError: "No se pudo generar la URL",
  verify: "Verificar",
  week: "Semana",
  weeks: "Semanas",
  withUSubscription: "Suscripción WithU",
  workoutsRange:
    "Puedes elegir de nuestra amplia variedad de estilos de entrenamiento, los cuales puedes realizar desde casa, en el gimnasio o dónde te encuentres, cuando quieras.",
  year: "año",
  yearly: "Anualmente",
  years: "años",
  redemptionComplete: "¡Redención completa!",
  redemptionCompleteBody: "Pulsa Hecho o Cerrar para terminar de configurar tu perfil",
};
