<template>
  <div>
    <div v-if="initLoading || hasLinkAndAdblock">
      <v-row no-gutters align="center" style="height: 100vh">
        <v-col>
          <loading-component v-if="initLoading" />
          <div
            v-else-if="hasLinkAndAdblock"
            style="max-width: 80vw; margin: 0 auto"
          >
            <v-alert type="error" border="left" text dense class="ma-0 my-2">
              {{ adBlockText }}
            </v-alert>
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="primary"
                rounded
                light
                class="white--text"
                @click="reload"
              >
                {{ tryAgainText }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="useDesktop">
        <desktop-view></desktop-view>
      </div>
      <div v-else>
        <mobile-view></mobile-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import mobileView from "./components/mobile-view";
import desktopView from "./components/desktop-view";
import loadingComponent from "@/components/loading";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  name: "Home",
  components: {
    mobileView,
    desktopView,
    loadingComponent,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    initLoading() {
      return this.account.initLoading;
    },
    useDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    adBlockText: () => getStringForKey(this.selectedLocale, "adBlock"),
    tryAgainText: () => getStringForKey(this.selectedLocale, "tryAgain"),
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>
