export const stringsEn = {
  adBlock:
    " Looks like you are trying to unlock a special offer but your Adblock is currently turned on. Please turn off your adblock and hit: Try again",
  afterTrial: "After trial ends",
  alreadyHaveSub: "You already have a WithU subscription!",
  alreadyRegistered: "This email is already registered with a WithU account.",
  annual: "Annual",
  annually: "Annually",
  anytime: "Workout anytime, anywhere",
  audioFitnessApp: "The Audio Fitness App",
  audioFitnessAppMobile: "The Audio\nFitness App",
  availableTo: "Available to",
  cancel: "Cancel",
  cancelAnytime: "Cancel anytime.",
  cancelAppStore:
    "Please cancel your App or Play Store payment to avoid being charged during the deal period.",
  cancelledRetry: "Looks like your purchase was cancelled. Want to try again?",
  cancelStripe:
    'To avoid being charged during the deal period, please drop us a message at <a href="mailto:support@withutraining.com">support@withutraining.com</a> and our team will help you with cancelling your Stripe payment.',
  cancelTitle: "It looks like you already have a paid subscription.",
  cardDetails: "Enter credit card details",
  choose: "Choose:",
  choosePlan: "choose your plan",
  claim: "Claim your offer",
  clickLink:
    "Please click on the link below to download WithU and activate your subscription;\n{{ link }}\n\nClick Log In to get started.",
    close: 'Close',
  coaches: "World-renowned coaches",
  codeError:
    "Oops, we could not verify your code. Please check and try again or please\nreach out to our support by tapping on the chat icon",
  codeRedeemed: "Code redeemed",
  compareOffer: "This is how the new offer compares:",
  completedNextSteps:
    "Download WithU from the App Store (iOS) or Play Store (Android) and login with the same email details used to redeem this offer.",
  confirmationEmail: "A confirmation email has been sent to",
  congratulations: "Congratulations!",
  createAccountErrorSubtitle: "Please try again or contact your link provider",
  createAccountErrorTitle: "There was an issue creating your account",
  createAnAccount: "Create an account",
  creditCard: "Credit card",
  currentPlan: "Current Plan",
  day: "day",
  days: "days",
  disciplines: "15 Different Disciplines",
  discount: "Discount",
  done: "Done",
  downloadApp: "Download the app then login",
  downloadAppMobile: "Download WithU",
  downloadAppMobileAndroid: "Play Store (Android)",
  downloadAppMobileIos: "App Store (iOS)",
  downloadInstructions:
    "To download WithU, head to the App Store (iOS) or Play Store (Android) on your phone, and search for\n'WithU: Audio Fitness App'.",
  emailAddress: "Email Address*",
  enterCardDetails: "Enter full card number",
  enterCode: "Enter code here",
  enterCodeHere: "Enter code here",
  enterPhoneNumber: "Enter a phone number",
  existingBest: "Your existing subscription is best!",
  fewMoreThings:
    "we just need a few more things from\nyou to activate your offer",
  free: "free",
  freeSubscription: "free subscription",
  freeTrial: "Start free trial",
  fullAccess: "Full access to WithU",
  fullName: "Full Name*",
  heresOffer: "here's your offer",
  hi: "Hi",
  intercomVisaMessage: "Entering my Visa card number does not work.",
  lifetime: "Lifetime's",
  loginCodeError: "Error verifying code",
  membersOnly: "members only.",
  monthly: "Monthly",
  months: "month's",
  monthsPlural: "months",
  newOffer: "New Offer",
  newOfferBenefit:
    "You will receive the benefits of the new offer as soon as your last payment period has ended.",
  noCommitment: "Cancel anytime, no commitment.",
  offer: "You've accessed an offer",
  offerCompare: "This is how the new offer compares:",
  offYour: "off your subscription",
  onDemandWorkouts: "1000+ on-demand workouts",
  oops: "Oops!",
  or: "or",
  partnered: "Partnered with",
  partnership: "Partnership deal",
  popupText: "to finish setting up your profile.",
  programmes: "Programmes for all abilities",
  purchaseCancelled: "Purchase Cancelled",
  qualified: "You've qualified for",
  rating: "#1 Audio Fitness App. Trusted Globally.",
  redeem: "Redeem",
  redeemCode: "Redeem code",
  reminder: "Get a reminder 7 days before your trial ends",
  seeWhatUsers: "See what our users think",
  sentCode: "We've sent a verification code to",
  smsDelays:
    "We are experiencing delays when sending emails to: Sky, Yahoo, AOL and Rocket email addresses. Please wait 30 minutes for your email to come through befoore contacting support",
  smsError: "Error sending SMS",
  smsMessage:
    "Thanks for joining WithU, let's get started. Hit the link below to download the app.",
  smsNumber:
    "For a link to download the app and get started, enter your phone number below.",
  smsSent: "SMS Sent",
  soundedSoGood: "Exercise never sounded so good.",
  startMembership: "Start Your Membership",
  subscribed: "You already have a WithU subscription!",
  subscription: "Subscription",
  subscriptionFreeTrial: "free trial",
  tap: "Tap",
  terms: "By signing up I agree to the WithU",
  termsEula: "EULA",
  termsMarketing:
    "Tick here if you do not wish to hear from WithU by email with information about your fitness progress, milestone celebrations, new features, products and more to keep you motivated.",
  termsPayment:
    "We require your payment information to initiate your account. We will remind you prior to charging your card and your WithU Membership will renew automatically at {{ amount }} until you cancel.",
  termsPaymentOneOff:
    "We require your payment information to initiate your account. You will be charged a one-time fee of {{ amount }} for a 6-month WithU Membership. There will be no automatic renewal after the 6-month period.",
  termsPaymentTrial:
    "We require your payment information to initiate the free trial, but you will not be charged until after the {{ freeTrial }} free trial period. We will remind you prior to charging your card and your WithU Membership will renew automatically after the trial period at {{ amount }} until you cancel.",
  termsPrivacy: "Privacy Policy",
  testimonial1:
    "“I never found time to go to the gym but 20 minutes in your living room a few times a week is so achievable and I have already noticed results!”",
  testimonial2:
    "“I've tried loads of apps but this one is the one for me. They are clear, encouraging…they are fun and have a huge range of different workouts for all.”",
  testimonial3:
    "“My whole family has actually got involved in sessions and the programme we are following is perfectly pitched at our level. 5/5!”",
  testimonial4:
    "“I loved completing a running programme on here, much better compared to others I have tried.”",
  testimonial5:
    "“I feel that having the audio cues and video is brilliant. Feel like there's loads to explore in this app, I pay monthly, great value”",
  testimonial6:
    "“It's like being in a class. Easy to do wherever you are so no excuses!”",
  thanksFor: "Thanks for subscribing to WithU!",
  thanksForJoining: "Thanks for joining WithU,",
  then: "Then",
  through: "through",
  toWithU: "to WithU",
  trial: "start 14 day free trial",
  tryAgain: "Try Again",
  unlocked: "Unlocked through",
  updatedSubscription: "We've updated your existing subscription for you",
  upgradedAccount: "Thanks for upgrading your account, you are all set",
  validationDataSharing:
    "Please agree to the data sharing conditions to create an account",
  validationEmailError: "Please provide a valid email address",
  validationName: "Please enter your full name",
  validationTerms:
    "Please agree to our terms and conditions to create an account",
  valivalidatonEmail: "Please enter your email address",
  vcesCidInvalid: "Looks like the card you entered is not eligible",
  vcesNotEligieable: "Card does not appear to be eligible",
  vcesUrlError: "Could not generate Url",
  verify: "Verify",
  week: "week",
  weeks: "weeks",
  withUSubscription: "WithU Subscription",
  workoutsRange:
    "We have an extensive range of different workout categories for you to choose from, accessible at home, in the gym or on the move. Whenever you want.",
  year: "year",
  yearly: "Yearly",
  years: "year's",
  redemptionComplete: "Redemption complete!",
  redemptionCompleteBody: "Tap Done or Close to finish setting up your profile",
};
