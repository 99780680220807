<template>
  <v-carousel height="240px" :hide-delimiters="true" class="">
    <template v-slot:prev="{ on }">
      <div class="white">
        <div class="grey-fill">
          <v-icon large color="black" v-on="on"> mdi-chevron-left </v-icon>
        </div>
      </div>
    </template>
    <template v-slot:next="{ on }">
      <div class="white">
        <div class="grey-fill">
          <v-icon large color="black" v-on="on"> mdi-chevron-right </v-icon>
        </div>
      </div>
    </template>
    <v-carousel-item v-for="item in carouselItems" :key="item.id">
      <v-row class="fill-height mx-16" align="center" justify="center">
        <v-col>
          <v-img
            class="mx-auto"
            :src="item.img"
            position="center"
            max-width="40"
            :eager="true"
          />
          <div class="mt-2 caption font-weight-bold text-center">
            {{ item.text }}
          </div>
          <div class="mt-2 text-center font-weight-bold secondaryGrey--text">
            {{ item.name }}
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template> 

<script>
import { getStringForKey } from "@/translations/utilities";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
    carouselItems() {
      return [
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial1"),
          name: "Becky_C",
        },
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial2"),
          name: "Charlmaile",
        },
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial3"),
          name: "Davie983",
        },
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial4"),
          name: "Timmy2606",
        },
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial5"),
          name: "127 Nell",
        },
        {
          img: require("@/assets/avatar-1.png"),
          text: getStringForKey(this.selectedLocale, "testimonial6"),
          name: "DR*+",
        },
      ];
    },
  },
};
</script>

<style scoped>
.grey-fill {
  background-color: rgba(112, 104, 130, 0.2);
}
</style>