
<template>
  <div>
    <div v-if="!loading && iFrameUrl">
      <div class="card-title mt-5">
        {{ cardDetailText }}
      </div>
      <iframe

        ref="iFrame"
        :src="iFrameUrl"
        title="Vces"
        class="frame"
      ></iframe>
    </div>
    <v-img
        v-else
        alt="WithU Loading"
        class="shrink mr-2"
        src="@/assets/splash-logo.gif"
        height="50"
        contain
    />
    <v-alert
      :value="error != null"
      type="error"
      border="left"
      text
      dense
      class="ma-0 my-2 text-caption"
      style="max-width: 500px"
    >
      {{ error }}
    </v-alert>
    <v-alert
      :value="showAlert"
      type="error"
      border="left"
      text
      dense
      class="ma-0 my-2 text-caption"
      style="max-width: 500px"
    >
       Hello World
    </v-alert>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  name: "Vces",
  components: {},
  data() {
    return {
      error: null,
      loading: false,
      validating: false,
      iFrame: null,
      errorCount: 0,
      showAlert: false,
    };
  },
  watch: {
    selectedLocale (value) {
      this.generateIFrame();
    },
    vcesErrorKey (value) {
      console.error("ERROR KEY: " + value);
      this.error = value;
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      redeem: (state) => state.redeem,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
      isEligible: "account/isVcesGranted",
      iFrameUrl: "account/getVcesFrameUrl",
      vcesErrorKey: "account/getVcesError",
    }),
    hasPartnershipBranding() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.external_partner.enabled"
      );
    },
    cardDetailText() {
      return getStringForKey(this.selectedLocale, 'enterCardDetails')
    },
    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    titleText() {
      if (this.hasPartnershipBranding) {
        var titleText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_title"
        );
        if (titleText != null) {
          titleText = titleText.replace(/(\\r)*\\n/g, "\n");
          return titleText;
        }
      }
      return getStringForKey(this.selectedLocale, "redeemCode");
    },
    placeholderText() {
      if (this.hasPartnershipBranding) {
        var placeholder = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_placeholder"
        );
        if (placeholder != null) return placeholder;
      }
      return getStringForKey(this.selectedLocale, "enterCodeHere");
    },
    buttonText() {
      if (this.hasPartnershipBranding) {
        var buttonText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_button"
        );

        if (buttonText != null) return buttonText;
      }
      return getStringForKey(this.selectedLocale, "redeem").toUpperCase();
    },
    themeColor() {
      if (this.hasPartnershipBranding) {
        return getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.external_partner.theme_color"
        );
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      generateIFrame: "account/vcesGenerateIFrameUrl",
      validateCid: "account/vcesValidateCid",
      switchLocale: "account/switchLocale",
    }),
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    incrementError() {
      this.errorCount++;
    },
    async validateCidEligible() {
      this.validating = true;
      this.error = null;

      this.validateCid()
        .then(() => {
          if (this.isEligible) {
            // console.log("validated cid successfully");
            this.error = null;
          } else {
            // console.log("cid was not validated: " + this.vcesErrorKey);
            this.error = stringForKey("vcesNotEligieable");

            this.setupIFrame();
            this.incrementError();
            this.checkError();
          }

          // this.account.isVcesGranted = true;
          this.validating = false;
        })
        .catch(() => {
          // console.log("failed to validate cid: " + this.vcesErrorKey);
          // this.error = this.vcesErrorKey;
          this.error = this.stringForKey("vcesCidInvalid");
          this.validating = false;

          this.setupIFrame();
          this.incrementError();
          this.checkError();
        });
    },
    checkError() {
      if(this.errorCount === 2 || this.errorCount === 5) {
        this.openIntercom();
      }
    },
    openIntercom() {
      // window.Intercom('showArticle', 'Visa Article ID')
      window.Intercom(
        'showNewMessage', 
        this.stringForKey("intercomVisaMessage"),
        {
          language_override: this.selectedLocale.key,
        }
      );
    },
    setupIFrame() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.generateIFrame()
        .then(() => {
          // console.log(`Got generated url (${this.iFrameUrl})`);
          this.loading = false;
        })
        .catch(() => {
          // console.log("failed to generate url");
          this.error = this.stringForKey("vcesUrlError");
          this.loading = false;
        });
    },
  },
  mounted()  {
    window.addEventListener('message', (e) => {
      console.log("MESSAGE: " + e.data.type);
      if (e.data.type === 'validate-cid') {
          // alert("VALIDATE CID: " + e.data.cid)
        console.log("VALIDATE CID: " + e.data.cid);
        this.account.isVcesGranted = true;
        this.validateCidEligible();
      }
    })

    // this.openIntercom(); // use for testing
    // this.incrementError();
    // this.incrementError();

    // if(this.errorCount === 2 || this.errorCount === 5) {
    //   this.openIntercom();
    // }
    console.log("ERROR: " + this.error);
  },
  beforeMount() {
    this.setupIFrame();
  },
};
</script>

<style scoped>
.enter-code-text {
  font-weight: bold;
  text-align: center;
  color: #0d002d;
}
.curved-container {
  width: 100%;
  height: 50%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0px;
  left: -25%;
  position: absolute;
  z-index: 0;
}

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 0.6;
  text-align: center;
  font-size: 20px;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
  text-align: center;
}

.sml-font {
  font-size: 12px;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
  text-align: center;
}

.frame {
  border-style: none;
  width: 100%;
  height: 200px;
}

.card-title {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 600px) {
  .card-title {
    font-family: "Roboto", sans-serif;
  }
}

</style>
