export const stringsPt = {
  adBlock:
    "Parece que você está tentando desbloquear uma oferta especial, mas seu bloqueador de anúncios está ativado no momento. Desative seu bloqueador de anúncios e clique em: Tente de novo",
  afterTrial: "Após o término do teste",
  alreadyHaveSub: "Você já tem uma assinatura WithU!",
  alreadyRegistered:
    "Este endereço de e-mail já está registrado com uma conta WithU",
  annual: "Anual",
  annualy: "Anualmente",
  anytime: "Treina a cualquer momento, em qualquer lugar",
  audioFitnessApp: "A App de Áudio Fitness",
  audioFitnessAppMobile: "A App de Fitness Áudio",
  availableTo: "Disponível a",
  cancel: "Cancelar",
  cancelAnytime: "Cancela em qualquer momento",
  cancelAppStore:
    "Por favor cancele seu pagamento com App ou Play Store para evitar ser cobrado durante o período da promoção.",
  cancelledRetry: "Parece que sua compra foi cancelada. Quere tentar de novo?",
  cancelStripe:
    'Para evitar ser cobrado durante o período de promoção, por favor envie-nos uma mensagem a <a href="mailto:support@withutraining.com">support@withutraining.com</a> e nossa equipa o ajudará com o cancelamento do seu pagamento com Stripe.',
  cancelTitle: "Parece que você já pagou a assinatura",
  cardDetails: "Insira os detalhes do cartão de crédito",
  choose: "Escolha",
  choosePlan: "Escolhe o seu plano",
  claim: "Solicite sua oferta",
  clickLink:
    "Por favor, clique no link abaixo para fazer download de WithU e ativar sua assinatura;\n{{ link }}\n\nPara começar, clique em iniciar sessão.",
    close: 'Fechar',
  coaches: "Treinadores de renome mundial",
  codeError:
    "Oops, não conseguimos verificar o seu código. Por favor, comprove e tenta de novo ou fale com nossa equipa de apoio, fazendo click no icono de chat.",
  codeRedeemed: "Código resgatado",
  compareOffer: "Veja como a nova oferta se compara",
  completedNextSteps:
    "Ir para a App Store (iOS) ou Play Store (Android) para fazer o download de WithU, ou faça click abaixo para fazer o download da app e começar",
  confirmationEmail: "O email de confirmação foi enviado para",
  congratulations: "Parabéns!",
  createAccountErrorSubtitle:
    "Por favor, tenta de novo ou entre em contato com o proveedor do link",
  createAccountErrorTitle: "Houve um problema na criação da sua conta",
  createAnAccount: "Cria uma conta",
  creditCard: "Cartão de crédito",
  currentPlan: "Plano atual",
  day: "dia",
  days: "dias",
  discount: "15 disciplinas diferentes",
  done: "Feito",
  downloadApp: "Desconto",
  downloadAppMobile: "Faça o download de WithU",
  downloadAppMobile: "Baixar WithU",
  downloadAppMobileAndroid: "Play Store (Android)",
  downloadAppMobileIos: "App Store (iOS)",
  downloadInstructions:
    "Para baixar o WithU, vá para a App Store (iOS) ou Play Store (Android) e procure por 'WithU: Audio Fitness App'.",
  emailAddress: "Endereço de e-mail*",
  enterCardDetails: "Insira o Número Completo do Cartão",
  enterCode: "Insira código de verificação",
  enterCodeHere: "Inserir código aqui",
  enterPhoneNumber: "Digite um número de teléfone",
  existingBest: "Sua assinatura existente é a melhor!",
  fewMoreThings:
    "Nós só precisamos apenas de mais algumas coisas de você para activar sua oferta",
  free: "gratuito",
  freeSubscription: "assinatura gratuita",
  freeTrial: "Iniciar o teste gratuito",
  fullAccess: "Acesso completo a WithU",
  fullName: "Nome completo",
  heresOffer: "Aqui está a sua oferta",
  hi: "Olá",
  intercomVisaMessage: "Digitar o número do meu cartão Visa não funciona.",
  lifetime: "Vida útil",
  loginCodeError: "Erro ao verificar o código",
  membersOnly: "somente para membros",
  monthly: "Mensualmente",
  months: "mês",
  monthsPlural: "meses",
  newOffer: "Nova oferta",
  newOfferBenefit:
    "Você receberá os benefícios da nova oferta uma vez seu último pagamento tenha finalizado",
  noCommitment: "Cancela em qualquer momento, sem compromisso",
  offer: "Você acedeu a uma oferta",
  offerCompare: "Veja como a nova oferta se compara",
  offYour: "Da sua assinatura",
  onDemandWorkouts: "+1000 treinos a pedido",
  oops: "Oops!",
  or: "ou",
  partnered: "Parcería com",
  partnership: "Acordo de parcería",
  popupText: "terminar de configurar seu perfil.",
  programmes: "Programas para todas as habilidades",
  purchaseCancelled: "Compra cancelada",
  qualified: "Você foi qualificado para",
  rating: "#1 App de Fitness Áudio. Confiável a nível mundial",
  redeem: "Resgatar",
  redeemCode: "Resgatar código",
  reminder:
    "Consegue um lembrete de 7 dias antes do término de seu período de teste",
  seeWhatUsers: "Veja o que os nossos usuários pensam",
  sentCode: "Enviamos uma verificação a",
  smsDelays:
    "Estamos enfrentando atrasos no envio de e-mails para: Sky, AOL e Rocket endereços de e-mail. Por favor, aguarde 30 minutos para que seu e-mail seja enviado antes de entrar en contato com Apoio ao cliente.",
  smsError: "Erro ao enviar SMS",
  smsMessage:
    "Obrigado por participar da WithU, vamos começar. Acesse o link abaixo para fazer o download da app",
  smsNumber:
    "Para obter o link e fazer o download da app e começar, insira seu número de telefone aqui abaixo.",
  smsSent: "SMS enviado",
  soundedSoGood: "O exercício nunca soou tão bem",
  startMembership: "Inicie sua assinatura",
  subscribed: "Você já tem uma assinatura WithU",
  subscription: "Assinatura",
  subscriptionFreeTrial: "Teste gratuito",
  tap: "Pressione",
  terms: "Ao me registrar, comcordo com os termos da WithU",
  termsEula: "CLUF",
  termsMarketing:
    "Marque aqui se você não deseja saber de WithU via email com informação sobre seu progreso fitness, celebrações de marcos históricos, novas caracteristicas, produtos e mais para mantê-lo motivado.",
  termsPayment:
    "Solicitamos sua informação de pagamento para inciar sua conta. Nós o lembraremos antes da cobrança em seu cartão e sua assinatura será renovada automaticamente a {{ valor }} até que você cancele.",
  termsPaymentOneOff:
    "Solicitamos suas informações de pagamento para iniciar sua conta. Será cobrada uma taxa única de {{ valor }} para uma assinatura de 6 meses da associação WithU",
  termsPaymentTrial:
    "Solicitamos suas informações de pagamento para iniciar o teste gratuito, mas você não será cobrado até o final do teste gratuito. Nós o lembraremos antes da cobrança em seu cartão e sua assinatura da WithU será renovada automaticamente após período de teste.",
  termsPrivacy: "Política de Privacidade",
  testimonial1:
    "Nunca tinha tempo para ir a academia mais com 20 minutos no salão alguma vez por semana é tão fácil de atingir que já comecei a ver resultados.",
  testimonial2:
    "Já tentei muitas apps mais essa é feita para mim. Os treinos são claros, incentivadores. Eles são divertidos e tem uma extensa variedade de treinos diferentes.",
  testimonial3:
    "Toda minha família começou com as sessoēs e o programa que estamos fazendo agora é perfeito para nosso nível. 5/5!",
  testimonial4:
    "Amei completar o programa de running na app, muito melhor comparada com outras que já tentei anteriormente.",
  testimonial5:
    "Acho que tendo as dicas em áudio e video é simplemente brilhante. Sinto como si ouvesse muito que explorar nesta app, eu pago mensualmente, ótimo valor.",
  testimonial6:
    "É como se eu estivesse numa aula. Fácil de fazer em qualquer lugar, sem excusas",
  thanksFor: "Obrigado por sua associação à WithU!",
  thanksForJoining: "Obrigado por se associar à WithU",
  then: "Depois",
  through: "através de",
  toWithU: "a WithU",
  trial: "Inicia 14 dias de teste gratuito.",
  tryAgain: "Tente de novo",
  unlocked: "desbloqueado através de",
  updatedSubscription: "Atualizamos sua assinatura existente",
  upgradedAccount: "Obrigado por fazer upgrade da sua conta, está tudo pronto",
  validationDataSharing:
    "Por favor, concorda com as condições de compartilhamento de dados para criar uma conta",
  validationEmailError: "Por favor, forneça um endereço de e-mail válido",
  validationName: "Por favor, insira seu nome completo",
  validationTerms:
    "Por favor, concorda com nossos termos e condições para criar uma conta",
  valivalidatonEmail: "Por favor, insira seu endereço de e-mail",
  vcesCidInvalid: "Parece que o cartão que você inseriu não é elegível",
  vcesNotEligieable: "O cartão não parece ser elegível",
  vcesUrlError: "Não foi possível gerar Url",
  verify: "Verificar",
  week: "Semana",
  weeks: "Semanas",
  withUSubscription: "Assinatura WithU",
  workoutsRange:
    "Temos uma extensa variedade de treinos diferentes para você escolher, accesível em casa, na academia ou em movimento. Quando seja.",
  year: "ano",
  yearly: "Anualmente",
  years: "anos",
  redemptionComplete: "Redenção completa!",
  redemptionCompleteBody: "Pressione Feito ou Fechar para terminar de configurar seu perfil",
};
