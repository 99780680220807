<template>
  <div class="mb-4">
    <div v-if="enteringCode" class="enter-code-text">
      <span style="white-space: pre-wrap" v-html="titleText"></span>
    </div>
    <div v-if="!enteringCode" class="d-flex align-center">
      <v-btn
        outlined
        color="primary"
        light
        class="black--text font-weight-bold px-10 text-subtitle-2"
        style="height: 30px"
        :style="hasDeal && 'opacity:0.4'"
        small
        @click="toggleEnteringCode"
        ><span>
          {{
            hasDeal ? stringForKey("codeRedeemed") : stringForKey("redeemCode")
          }}
        </span>
      </v-btn>
      <v-icon v-if="hasDeal" color="secondary" class="ml-3">
        mdi-checkbox-marked-circle-outline
      </v-icon>
    </div>
    <div
      v-else
      class="d-flex align-center mt-1"
      :class="isMobile ? 'flex-column' : 'flex-row'"
    >
      <input
        v-model="redeemCode"
        class="
          cardElement
          ma-0
          font-weight-bold
          black--text
          custom-placeholer-color
          sml-font
        "
        :placeholder="placeholderText"
        autocomplete="off"
        v-on:keypress="checkCode($event)"
        v-on:keyup.enter="redeem"
      />
      <v-btn
        :color="themeColor != null ? `#${themeColor}` : 'primary'"
        light
        class="white--text font-weight-bold text-subtitle-1"
        style="height: 40px"
        :class="isMobile ? 'mt-3' : 'ml-2'"
        :block="isMobile"
        @click="redeem"
        :disabled="!hasEnteredCode || loading"
        :loading="loading"
      >
        <span> {{ buttonText }} </span>
      </v-btn>
      <!-- <v-btn
        light
        small
        text
        class="ml-2"
        :class="isMobile && 'mt-2'"
        @click="toggleEnteringCode"
      >
        Cancel</v-btn
      > -->
    </div>
    <v-alert
      class="font-weight-medium text-caption mt-2"
      color="red"
      border="left"
      text
      dense
      v-if="error && enteringCode"
    >
      {{ stringForKey("codeError") }}</v-alert
    >
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getStringForKey, getDealStringForKey } from "@/translations/utilities";

export default {
  name: "Redeem",
  components: {},
  props: {
    enteringCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      redeemCode: "",
      error: false,
      codeDialog: false,
      loading: false,
      validCodeRegExp: /^[A-Za-z0-9-]+$/,
    };
  },
  watch: {
    redeemCode: function (val) {
      if (val == null) {
        this.redeemCode = "";
        return;
      }
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      selectedLocale: "account/getSelectedLocale",
      dealStrings: "account/getDealStrings",
    }),
    isMobile() {
      return true; //this.$vuetify.breakpoint.xs;
    },
    hasPartnershipBranding() {
      return getDealStringForKey(
        this.dealStrings,
        this.selectedLocale,
        "content.external_partner.enabled"
      );
    },
    hasEnteredCode() {
      return this.redeemCode != null && this.redeemCode != "";
    },
    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    titleText() {
      if (this.hasPartnershipBranding) {
        var titleText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_title"
        );
        if (titleText != null) {
          titleText = titleText.replace(/(\\r)*\\n/g, "\n");
          return titleText;
        }
      }
      return getStringForKey(this.selectedLocale, "redeemCode");
    },
    placeholderText() {
      if (this.hasPartnershipBranding) {
        var placeholder = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_placeholder"
        );
        if (placeholder != null) return placeholder;
      }
      return getStringForKey(this.selectedLocale, "enterCodeHere");
    },
    buttonText() {
      if (this.hasPartnershipBranding) {
        var buttonText = getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.redeem.textbox_button"
        );
        if (buttonText != null) return buttonText;
      }
      return getStringForKey(this.selectedLocale, "redeem").toUpperCase();
    },
    themeColor() {
      if (this.hasPartnershipBranding) {
        return getDealStringForKey(
          this.dealStrings,
          this.selectedLocale,
          "content.external_partner.theme_color"
        );
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      verify: "account/verifyCode",
      setLoading: "account/setLoading",
    }),
    reload() {
      window.location.reload();
    },
    checkCode(e) {
      if (e.keyCode) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (this.validCodeRegExp.test(char)) return true;
        // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      }
    },
    async redeem() {
      this.loading = true;
      this.error = false;

      // remove invalid characters from this.redeem
      let sanatisedCode = "";

      for (let char of this.redeemCode) {
        if (this.validCodeRegExp.test(char)) {
          sanatisedCode += char;
        }
      }

      this.redeemCode = sanatisedCode;

      var result = await this.verify(this.redeemCode);
      if (result && result.deal) {
        this.error = false;
        this.codeDialog = false;
        this.toggleEnteringCode();
      } else {
        this.error = true;
      }

      this.loading = false;
    },
    register() {
      this.$router.push("/register");
    },
    toggleEnteringCode() {
      this.$emit("toggle", !this.enteringCode);
    },
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
  },
};
</script>

<style>
.enter-code-text {
  font-weight: bold;
  text-align: center;
  color: #0d002d;
}
.curved-container {
  width: 100%;
  height: 50%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0px;
  left: -25%;
  position: absolute;
  z-index: 0;
}

/* .centered-input >>> * {
  text-align: center;
  color: white !important;
} */

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 0.6;
  text-align: center;
  font-size: 20px;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
  text-align: center;
}

.sml-font {
  font-size: 12px;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
  text-align: center;
}
</style>
