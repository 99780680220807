import axios from "axios";

export const service = {
  get,
  post,
};

function get(url) {
  // console.log(axios.defaults.headers)
  return axios.get(process.env.VUE_APP_API_URL + url).then(handleResponse);
}

function post(url, json) {
  // console.log((process.env.VUE_APP_API_URL + url);
  return axios
    .post(process.env.VUE_APP_API_URL + url, json)
    .then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("company");
  axios.defaults.headers.common["Authorization"] = null;
}

function handleResponse(response) {
  const data = response.data;

  if (data.error) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }

  return data;
}
