export const subsciptionPaymentHandler = {
    STRIPE: "stripe",
    APP_STORE: "app_store",
    PLAY_STORE: "play_store",
    PROMO: "promo",
    HUAWEI: "huawei",
};

export const subscriptionActivationState = {
    NEW: "new",
    ACTIVE: "active",
    EXPIRED: "expired",
    CANCELLED: "cancelled",
};


export const vcesErrorCodes = [
    {
      "statusCode": "000", 
      "httpStatusCode": 200,
      "statusCodeDescription": "Success",
      "details": "Successful Validation",
      "operations": ["Validate", "Validate Result", "Validate iFrame"],
      "message": "",
    },
    {
      "statusCode": "001",
      "httpStatusCode": 200,
      "statusCodeDescription": "Ineligible card for this program",
      "details": "Ineligible Validation",
      "operations": ["Validate", "Validate Result", "Validate iFrame"],
      "messageKey": "This card is not eligible for this offer.",
    },
    {
      "statusCode": "002",
      "httpStatusCode": 200,
      "statusCodeDescription": "Ineligible card account",
      "details": "Ineligible Validation",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "003",
      "httpStatusCode": 200,
      "statusCodeDescription": "Success validations limit reached",
      "details": "Validation limit reached",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "004",
      "httpStatusCode": 200,
      "statusCodeDescription": "Redemptions limit reached",
      "details": "Redemption limit reached",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "005",
      "httpStatusCode": 200,
      "statusCodeDescription": "True (Response to Prepayment request)",
      "details": "Successful PrePay",
      "operations": ["PrePay", "PrePay iFrame"]
    },
    {
      "statusCode": "006",
      "httpStatusCode": 200,
      "statusCodeDescription": "False (response to Prepayment request)",
      "details": "Ineligible PrePay",
      "operations": ["PrePay", "PrePay iFrame"]
    },
    {
      "statusCode": "007",
      "httpStatusCode": 200,
      "statusCodeDescription": "Prepayment validation Success But card is ineligible",
      "details": "Correlation ID exists but Validation is ineligible",
      "operations": ["PrePay", "PrePay iFrame"]
    },
    {
      "statusCode": "008",
      "httpStatusCode": 200,
      "statusCodeDescription": "Redemption transaction successfully matched to 'ineligible' transactions",
      "details": "Correlation ID exists but Redemption is ineligible",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "009",
      "httpStatusCode": 200,
      "statusCodeDescription": "Redemption transaction unsuccessfully matched",
      "details": "Correlation ID does not exist",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "010",
      "httpStatusCode": 200,
      "statusCodeDescription": "Failed to retrieve Card Benefit details",
      "details": "Unable to retrieve card benefits",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "011",
      "httpStatusCode": 200,
      "statusCodeDescription": "Redemption transaction success",
      "details": "Successful Redemption",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "016",
      "httpStatusCode": 200,
      "statusCodeDescription": "Redemption transaction for given vendor and correlation Id already exists",
      "details": "Attempt to Redeem using same Correlation ID already used",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "017",
      "httpStatusCode": 200,
      "statusCodeDescription": "Validation and Redemption transaction for given vendor and correlation Id already exists",
      "details": "Correlation ID used during Validation is already redeemed",
      "operations": ["Validate Result"]
    },
    {
      "statusCode": "018",
      "httpStatusCode": 200,
      "statusCodeDescription": "ValidationResult transaction successfully matched to ineligible transactions",
      "details": "Ineligible Validation",
      "operations": ["Validate Result"]
    },
    {
      "statusCode": "019",
      "httpStatusCode": 200,
      "statusCodeDescription": "ValidationResult transaction unsuccessfully matched",
      "details": "Correlation ID does not exist",
      "operations": ["Validate Result"]
    },
    {
      "statusCode": "020",
      "httpStatusCode": 200,
      "statusCodeDescription": "Token Request for submitted correlation ID and vendor is expired",
      "details": "Expired iFrame token",
      "operations": ["GetToken"]
    },
    {
      "statusCode": "021",
      "httpStatusCode": 200,
      "statusCodeDescription": "Token Request for submitted correlation ID and vendor is already used",
      "details": "iFrame token already used",
      "operations": ["GetToken"]
    },
    {
      "statusCode": "022",
      "httpStatusCode": 200,
      "statusCodeDescription": "Invalid Transaction. Please verify",
      "details": "iFrame / Web Service used for first operation but a different API used for subsequent operations",
      "operations": ["All"]
    },
    {
      "statusCode": "023",
      "httpStatusCode": 200,
      "statusCodeDescription": "Vendor is not configured for NxtGen Iframe. Please verify",
      "details": "Not configured for iFrame",
      "operations": ["GetToken"]
    },
    {
      "statusCode": "024",
      "httpStatusCode": 200,
      "statusCodeDescription": "Reverse Redemption request for submitted correlation ID and vendor already exists",
      "details": "Reverse Redemption already performed",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "025",
      "httpStatusCode": 200,
      "statusCodeDescription": "Reverse Redemption transaction unsuccessfully matched",
      "details": "Correlation ID does not exist to perform Reverse Redemption",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "026",
      "httpStatusCode": 200,
      "statusCodeDescription": "Reverse redemption Successful",
      "details": "Successful Reverse Redemption",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "027",
      "httpStatusCode": 200,
      "statusCodeDescription": "Reverse redemption not possible",
      "details": "Reverse Redemption attempted on a day other than the day the Redemption operation occurred",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "028",
      "httpStatusCode": 200,
      "statusCodeDescription": "Reverse redemption is not set for the selected vendor",
      "details": "Reverse Redemption not enabled",
      "operations": ["Redeem", "iFrame Redemption", "Redeem iFrame"]
    },
    {
      "statusCode": "029",
      "httpStatusCode": 200,
      "statusCodeDescription": "Vendor is not configured for this transaction. Please verify",
      "details": "iFrame implementation trying to invoke Web Service APIs",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "030",
      "httpStatusCode": 400,
      "statusCodeDescription": "AVS Service returned error",
      "details": "AVS error",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "031",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid request object. Please check",
      "details": "Incorrect or invalid expiry month",
      "operations": ["Validate"]
    },
    {
      "statusCode": "032",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid request object. Please check",
      "details": "Incorrect or invalid expiry year",
      "operations": ["Validate"]
    },
    {
      "statusCode": "033",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid request object. Please check",
      "details": "Expiry month AND / OR year are null when required for AVS",
      "operations": ["Validate"]
    },
    {
      "statusCode": "034",
      "httpStatusCode": 200,
      "statusCodeDescription": "Partial Success",
      "details": "Validation operation is partially successful based on redemption configuration",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "101",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid Vendor Unique Id",
      "details": "VUID has more than 20 characters",
      "operations": ["All"]
    },
    {
      "statusCode": "102",
      "httpStatusCode": 400,
      "statusCodeDescription": "Correlation ID length error",
      "details": "Correlation ID has less than 10 OR more than 25 characters",
      "operations": ["All"]
    },
    {
      "statusCode": "103",
      "httpStatusCode": 400,
      "statusCodeDescription": "Vendor RequestTimeStamp format error",
      "details": "Time stamp is invalid OR not within the timeframe OR not in UTC format",
      "operations": ["All"]
    },
    {
      "statusCode": "104",
      "httpStatusCode": 400,
      "statusCodeDescription": "Card Account format error",
      "details": "PAN is not 16 digits",
      "operations": ["Validate", "PrePay", "Redeem"]
    },
    {
      "statusCode": "105",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid request object. Please check",
      "details": "When any required field is empty",
      "operations": ["All"]
    },
    {
      "statusCode": "107",
      "httpStatusCode": 400,
      "statusCodeDescription": "Invalid input",
      "details": "If passing more than one of PAN, Token, or Alias ID OR passing spaces in a value",
      "operations": ["All"]
    },
    {
      "statusCode": "201",
      "httpStatusCode": 400,
      "statusCodeDescription": "Vendor Unique Id is invalid",
      "details": "VUID does not exist",
      "operations": ["All"]
    },
    {
      "statusCode": "202",
      "httpStatusCode": 400,
      "statusCodeDescription": "Vendor Unique Id is inactive",
      "details": "VUID is inactive",
      "operations": ["All"]
    },
    {
      "statusCode": "300",
      "httpStatusCode": 500,
      "statusCodeDescription": "System Exception",
      "details": "Service interruption",
      "operations": ["All"]
    },
    {
      "statusCode": "500",
      "httpStatusCode": 400,
      "statusCodeDescription": "Mod 10 Check of the PAN has failed",
      "details": "Failed MOD 10 check",
      "operations": ["Validate", "Validate Result", "Validate iFrame"]
    },
    {
      "statusCode": "666",
      "httpStatusCode": 500,
      "statusCodeDescription": "An error has occurred",
      "details": "Service interruption",
      "operations": ["All"]
    }
  ];