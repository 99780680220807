import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"button-body row align-center justify-center"},'div',attrs,false),on),[_c('div',{staticClass:"row align-center justify-center"},[_c('flag',{attrs:{"code":_vm.selectedLocale.flag}}),_c('div',{staticClass:"ml-2 text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedLocale.key.toUpperCase())+" ")])],1)])]}}])},[_c(VList,_vm._l((_vm.selectableLocales),function(locale,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.changedLocale(locale)}}},[_c('div',{staticClass:"row align-center justify-center"},[_c('flag',{attrs:{"code":locale.flag}}),_c('div',{staticClass:"ml-2 text-caption font-weight-bold"},[_vm._v(" "+_vm._s(locale.key.toUpperCase())+" ")])],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }