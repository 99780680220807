export const getProducts = () => {
    if (process.env.VUE_APP_DEV_MODE === "true") {
      return devProducts;
    }  
    return products;
};




/** @todo Implement this once Environment Variable are correctly handled in Amplify */
const products = [
    {
      product_id: "prod_JJWLQpeaiC5qbW",
      term: "year",
      prices: [
        {
          price_id: "price_1IgtJ1LdUrrOSabidKBy5FOC",
          currency: "GBP",
          symbol: "£",
          price: 79.99,
          subunit: 7999,
        },
        {
          price_id: "price_1IgtJ1LdUrrOSabiOsDCYCuz",
          currency: "USD",
          symbol: "$",
          price: 79.99,
          subunit: 7999,
        },
        {
          price_id: "price_1IgtJ1LdUrrOSabiG80pxkve",
          currency: "EUR",
          symbol: "€",
          price: 79.99,
          subunit: 7999,
        },
      ],
    },
    {
      product_id: "prod_JJWLQpeaiC5qbW",
      term: "month",
      prices: [
        {
          price_id: "price_1IgtJ1LdUrrOSabi78fJZp2W",
          currency: "GBP",
          symbol: "£",
          price: 9.99,
          subunit: 999,
        },
        {
          price_id: "price_1IgtJ1LdUrrOSabiJlC8jx68",
          currency: "USD",
          symbol: "$",
          price: 9.99,
          subunit: 999,
        },
        {
          price_id: "price_1IgtJ1LdUrrOSabibwyNL7Pp",
          currency: "EUR",
          symbol: "€",
          price: 9.99,
          subunit: 999,
        },
      ],
    },
  ];
const devProducts = [
{
    product_id: "prod_IzmxqbqpMRV1YH",
    term: "year",
    prices: [
    {
        price_id: "price_1IgpxsLdUrrOSabihr59aEjg",
        currency: "GBP",
        symbol: "£",
        price: 79.99,
        subunit: 7999,
    },
    {
        price_id: "price_1Igpy0LdUrrOSabiu3DEPl7F",
        currency: "USD",
        symbol: "$",
        price: 79.99,
        subunit: 7999,
    },
    {
        price_id: "price_1Igpy9LdUrrOSabiDop6r4ZI",
        currency: "EUR",
        symbol: "€",
        price: 79.99,
        subunit: 7999,
    },
    ],
},
{
    product_id: "prod_IzmxqbqpMRV1YH",
    term: "month",
    prices: [
    {
        price_id: "price_1IgpxELdUrrOSabiI5CaHsZp",
        currency: "GBP",
        symbol: "£",
        price: 9.99,
        subunit: 999,
    },
    {
        price_id: "price_1IgpxSLdUrrOSabiwqpOSLl9",
        currency: "USD",
        symbol: "$",
        price: 9.99,
        subunit: 999,
    },
    {
        price_id: "price_1IgpxfLdUrrOSabisYepW7Py",
        currency: "EUR",
        symbol: "€",
        price: 9.99,
        subunit: 999,
    },
    ],
},
];
